// @flow
import { useState, useEffect } from 'react';
import icons from './icons';
import * as colors from './colors';
import styled, { css } from 'styled-components/macro';
import strings from './strings';
import { darken } from 'polished';
import { usePopper } from 'react-popper';

export const defaultIconNames = [
  'turnip',
  'celeste',
  'gift-red',
  'scheme',
  'star-yellow',
  // 'apple',
  // 'cherry',
  // 'peach',
  // 'orange',
  // 'pear',
  // 'coconut',
  'furniture',
];

type IconBoxProps = {
  icon: string,
  value: string,
  active: ?string,
  index: number,
  disabled: boolean,
  size: number,
  onChange: (any) => void,
  className?: string,
  noCheckedStyle: boolean,
};

export const IconBox: React$ComponentType<IconBoxProps> = styled(
  ({
    icon,
    value,
    active,
    index,
    disabled,
    size,
    onChange,
    className,
    noCheckedStyle,
  }: IconBoxProps) => {
    const [reference, setReference] = useState();
    const [popper, setPopper] = useState();
    const { styles } = usePopper(reference, popper, {
      placement: 'top',
      modifiers: [
        {
          name: 'computeStyles',
          options: { gpuAcceleration: false, adaptive: false },
        },
        { name: 'offset', options: { offset: [0, -12] } },
      ],
    });
    const checked = active != null ? value === active : index === 0;
    return (
      <>
        <input
          type="radio"
          name="icon"
          value={value}
          id={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          css="all: unset; opacity: 0; visibility: hidden;"
        />
        <label
          size={size}
          htmlFor={value}
          tabIndex={0}
          onKeyDown={(evt) =>
            evt.key === 'Enter' &&
            document.getElementById(evt.currentTarget.getAttribute('for'))?.click()
          }
          css={`
            position: relative;
            flex-shrink: 0;
            display: inline-flex;
            border-radius: 10px;
            background-color: ${colors.pale};
            @media (prefers-color-scheme: dark) {
              background-color: ${colors.darkPale};
            }
            margin: 10px;
            width: ${
              // $FlowFixMe[incompatible-type]
              (props) => props.size
            }px;
            height: ${
              // $FlowFixMe[incompatible-type]
              (props) => props.size
            }px;

            @media (max-width: 800px) {
              width: ${
                // $FlowFixMe[incompatible-type]
                (props) => props.size * 0.8
              }px;
              height: ${
                // $FlowFixMe[incompatible-type]
                (props) => props.size * 0.8
              }px;
            }

            input:checked + & > div {
              z-index: 2;
            }

            &:focus,
            input:checked + &:focus {
              outline: 0;
              & > div {
                z-index: 2;
              }
            }
          `}
          className={className}
        >
          <div
            ref={setReference}
            disabled={disabled}
            size={size}
            icon={icon}
            noCheckedStyle={noCheckedStyle}
            css={`
              z-index: 1;
              position: relative;
              display: inline-block;
              cursor: ${
                // $FlowFixMe[incompatible-type]
                (props) => (props.disabled ? 'default' : 'pointer')
              };
              flex: 1;
              border-radius: 10px;
              border: ${
                  // $FlowFixMe[incompatible-type]
                  (props) => props.size / 30
                }px
                solid transparent;
              filter: grayscale(0.7);
              background-image: url(${
                // $FlowFixMe[incompatible-type]
                (props) => props.icon
              });
              background-size: 80% 80%;
              background-repeat: no-repeat;
              background-position: center;
              transition: all 0.1s ease-in-out;

              input:disabled + label > &,
              input:checked:disabled + label > & {
                filter: grayscale(1);
                transform: scale(1);
                border-color: transparent;
              }

              input:checked + label > & {
                filter: grayscale(0);
              }

              ${
                // $FlowFixMe[incompatible-type]
                (props) =>
                  !props.noCheckedStyle &&
                  css`
                    input:disabled + label > & {
                      filter: grayscale(0);
                      border-bottom: ${props.size / 30}px dashed ${colors.border};
                      transform: scale(1.1);
                    }
                  `
              }
            `}
          />
          <span
            ref={setPopper}
            style={styles.popper}
            css={`
              z-index: 1;
              position: absolute;
              display: block;
              background: ${darken(0.1, colors.bg)};
              border-radius: 30px;
              padding: 0 0.5em;
              opacity: 0;
              transform: scale(0.5);
              white-space: nowrap;
              color: #000;
              transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;

              input:not(:checked) + label:focus:not(:active) > & {
                filter: grayscale(1);
                opacity: 1;
                transform: scale(1);
                z-index: 2;
              }
              input:checked + label > & {
                opacity: 1;
                transform: scale(1);
                filter: grayscale(0);
              }
            `}
          >
            {strings[value]}
          </span>
        </label>
      </>
    );
  }
)``;

const CategoriesSelector = ({
  active: defaultActive,
  loading = false,
  size = 66,
  onChange,
  iconNames = defaultIconNames,
  className,
  noCheckedStyle = false,
}: {
  active?: string,
  loading?: boolean,
  size?: number,
  onChange?: (any) => void,
  iconNames?: string[],
  className?: string,
  noCheckedStyle?: boolean,
}): React$Node => {
  const [active, setActive] = useState(defaultActive);
  useEffect(() => {
    setActive(defaultActive);
  }, [defaultActive]);

  return (
    <div className={className} css="isolation: isolate;">
      {iconNames.map((icon, index) => (
        <IconBox
          key={icon ? icon : `loading${index}`}
          icon={icons[icon]}
          value={icon}
          active={active}
          index={index}
          disabled={loading}
          size={size}
          onChange={(evt) => {
            onChange && onChange(evt);
            setActive(evt.target.value);
          }}
          noCheckedStyle={noCheckedStyle}
        />
      ))}
    </div>
  );
};

export default CategoriesSelector;
