// @flow
import 'styled-components/macro';
import { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSessionStorage } from 'react-use-storage';
import IslandManager from './IslandManager';
import JoinIslandWaitingList from './JoinIslandWaitingList';
import User from './User';
import SubscribePremium from './SubscribePremium';
import './styles.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Container, GlobalStyle } from './styles';
import strings from './strings';
import Home from './Home';
import usePushNotifications from './pushNotifications';
import { useActiveIslandsListener } from './useActiveIslands';
import BanListManagement from './BanListManagement';
import { YEARLY_PLAN, MONTHLY_PLAN } from './premium';
import { useCustomClaimRoleFromUser } from './utils/useCustomClaimRoleFromUser';
import ServiceWorkerUI from './ServiceWorkerUI';
// import LogRocket from 'logrocket';
import { unregister } from './serviceWorkerRegistration';
import firebase from 'firebase/app';
import 'firebase/database';
import { firestore, database, auth } from './firebase';
import TwitchLogin from './TwitchLogin';

export default function App(): React$Node {
  useActiveIslandsListener();
  const [showLoadingAnimation, setShowLoadingAnimation] = useSessionStorage(
    'show-loading-animation',
    JSON.stringify(true)
  );
  const [user, loggingIn] = useAuthState(auth);
  const [role] = useCustomClaimRoleFromUser(user);
  const [island] = useObjectVal(user != null ? database.ref(`islands/${user.uid}`) : null);

  // useEffect(() => {
  //   if (user != null) {
  //     LogRocket.init('138jjo/my-dodo-link');
  //     LogRocket.identify(user.uid, {
  //       name: user.displayName,
  //       email: user.email,
  //       role,
  //     });
  //   }
  // }, [user, role]);

  useEffect(() => {
    if (user == null) return;

    window.gtag?.('set', 'user_properties', {
      user_id: user.uid,
      role,
    });
  }, [user, role]);

  usePushNotifications({ user });

  const [loading, setLoading] = useState(true);
  const [loadingTimeout, setLoadingTimeout] = useState(true);
  useEffect(() => {
    const id = setTimeout(() => {
      setLoadingTimeout(false);
      setShowLoadingAnimation(JSON.stringify(false));
    }, 1300);
    return () => clearTimeout(id);
  });

  const hasIsland = island?.island != null;

  useEffect(() => {
    if (user?.uid == null) {
      return;
    }

    const userLastOnlineRef = database.ref(`users/${user.uid}/lastOnline`);
    userLastOnlineRef.set(firebase.database.ServerValue.TIMESTAMP);

    let islandOnlineInterval;
    const islandLastOnlineRef = database.ref(`islands/${user.uid}/lastOnline`);

    if (hasIsland) {
      islandLastOnlineRef.set(firebase.database.ServerValue.TIMESTAMP);
      islandOnlineInterval = setInterval(() => {
        islandLastOnlineRef.set(firebase.database.ServerValue.TIMESTAMP);
      }, 5 * 60 * 1000);
    }

    return () => {
      clearInterval(islandOnlineInterval);
    };
  }, [user?.uid, hasIsland]);

  useEffect(() => {
    firestore
      .collection('config')
      .doc('forceUnregister')
      .get()
      .then((forceUnregister) => {
        if (forceUnregister.data()?.enabled === true) {
          unregister();
        }
      });
  }, []);

  const fillLoading =
    loading || loggingIn || (loadingTimeout && JSON.parse(showLoadingAnimation) === true);

  return (
    <>
      <GlobalStyle />
      <ServiceWorkerUI />
      <div
        css={`
          flex: 1;
          display: flex;
          flex-direction: column;
          min-height: 0;
        `}
      >
        <Switch>
          <Route
            path="/is/:slug"
            exact
            render={({ match }) => (
              <JoinIslandWaitingList
                // $FlowFixMe[incompatible-type]
                slug={match.params.slug}
                user={user}
                loggingIn={loggingIn}
              />
            )}
          />
          <Route
            path="/u/:uid"
            exact
            render={({ match }) => (
              <User uid={match.params.uid} currentUser={user} loggingIn={loggingIn} />
            )}
          />
          <Route
            path="/u/me/subscribe-premium/monthly"
            exact
            render={() => <SubscribePremium plan={MONTHLY_PLAN} />}
          />
          <Route
            path="/u/me/subscribe-premium/yearly"
            exact
            render={() => <SubscribePremium plan={YEARLY_PLAN} />}
          />
          <Redirect from="/u/me/subscribe-premium" exact to="/u/me/subscribe-premium/monthly" />
          <Route
            path="/island-manager"
            exact
            render={() =>
              user ? (
                <IslandManager user={user} />
              ) : loggingIn ? (
                <Container loading={loading} loadingText={strings.loading} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/banlist-management"
            exact
            render={() => <BanListManagement currentUser={user} loggingIn={loggingIn} />}
          />

          <Route path="/twitch-login" exact component={TwitchLogin} />

          <Route
            path="/"
            render={() => (
              <Home
                setLoading={setLoading}
                fillLoading={fillLoading}
                user={user}
                loggingIn={loggingIn}
                showLoadingAnimation={JSON.parse(showLoadingAnimation)}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
}
