import styled from 'styled-components/macro';
import * as colors from '../colors';

const Table = styled.table`
  table-layout: fixed;
  width: 100%;

  th,
  td {
    background-color: ${colors.paper};
    @media (prefers-color-scheme: dark) {
      background-color: ${colors.darkPaper};
    }
  }

  th:first-child:not(.even-cols th),
  td:first-child:not(.even-cols td) {
    width: 4em;
  }

  &.small th:last-child:not(.even-cols th),
  &.small td:last-child:not(.even-cols td) {
    width: 3em;
  }
  &.large th:last-child:not(.even-cols th),
  &.large td:last-child:not(.even-cols td) {
    width: 7em;
  }

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  th {
    text-align: left;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const ScrollableTableContainer = styled.div`
  overflow: auto;
  margin-top: 10px;
  max-height: 400px;
  @supports (-webkit-touch-callout: none) {
    min-height: fit-content;
  }
`;

export { Table, ScrollableTableContainer };
