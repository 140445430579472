// @flow
import 'styled-components/macro';

const icons = {
  // $FlowFixMe[prop-missing]
  bell: require('./icons/bell.png').default ?? require('./icons/bell.png'),
  // $FlowFixMe[prop-missing]
  celeste: require('./icons/celeste.png').default ?? require('./icons/celeste.png'),
  // $FlowFixMe[prop-missing]
  'nook-ticket': require('./icons/nook-ticket.png').default ?? require('./icons/nook-ticket.png'),
  // $FlowFixMe[prop-missing]
  palm: require('./icons/palm.png').default ?? require('./icons/palm.png'),
  // $FlowFixMe[prop-missing]
  scheme: require('./icons/scheme.png').default ?? require('./icons/scheme.png'),
  // $FlowFixMe[prop-missing]
  schemes: require('./icons/schemes.png').default ?? require('./icons/schemes.png'),
  // $FlowFixMe[prop-missing]
  'star-yellow': require('./icons/star-yellow.png').default ?? require('./icons/star-yellow.png'),
  // $FlowFixMe[prop-missing]
  'star-purple': require('./icons/star-purple.png').default ?? require('./icons/star-purple.png'),
  // $FlowFixMe[prop-missing]
  turnip: require('./icons/turnip.png').default ?? require('./icons/turnip.png'),
  // $FlowFixMe[prop-missing]
  island: require('./images/island.svg').default ?? require('./images/island.svg'),
};

const Icon = ({ icon, size, ...props }: { icon: string, size: number }): React$Node => {
  return (
    <img
      {...props}
      src={icons[icon]}
      alt={icon}
      css={`
        width: ${size}px;
        height: ${size}px;
      `}
    />
  );
};

export default Icon;
