import React, { useCallback } from 'react';
import 'styled-components/macro';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import strings from './strings';
import { Google } from '@icons-pack/react-simple-icons';
import { Facebook, Twitch } from 'react-feather';
import { useHistory } from 'react-router-dom';

export const TwitchLogin = ({ loggingIn, children, className, token }) => {
  const history = useHistory();
  const twitchLogin = useCallback(async () => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    window.gtag?.('event', 'login', {
      method: 'Twitch',
    });

    history.push(`/twitch-login${token ? `?link_token=${token}` : ''}`);
  }, [history, token]);

  return (
    <button
      className={className}
      css={`
        cursor: pointer;
        background-color: #9146ff;
        border-radius: 20px;
        padding: 15px 20px;
        color: #fff;
        margin-top: 20px;
        text-align: center;
        margin: 5px;
        @media (max-width: 800px) {
          padding: 10px 10px;
        }
      `}
      type="button"
      disabled={loggingIn}
      onClick={twitchLogin}
      title={strings.loginWithTwitch}
    >
      <Twitch />
      {children && <span css="margin-left: 1em">{children}</span>}
    </button>
  );
};

const Login = ({ loggingIn }) => {
  const facebookLogin = useCallback(async () => {
    try {
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().useDeviceLanguage();

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

      window.gtag?.('event', 'login', {
        method: 'Facebook',
      });
      await firebase.auth().signInWithRedirect(provider);
    } catch (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.error(errorCode, errorMessage, email, credential);
    }
  }, []);

  const googleLogin = useCallback(async () => {
    try {
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().useDeviceLanguage();

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

      window.gtag?.('event', 'login', {
        method: 'Google',
      });
      await firebase.auth().signInWithRedirect(provider);
    } catch (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.error(errorCode, errorMessage, email, credential);
    }
  }, []);

  return (
    <div
      css={`
        @media (max-width: 800px) {
          margin-top: 20px;
        }
      `}
    >
      <p>{strings.loginDescription}</p>
      <div css="margin: 5px auto; text-align: center;">
        <button
          css={`
            cursor: pointer;
            background-color: #4267b2;
            border-radius: 20px;
            padding: 15px 20px;
            color: #ffffff;
            margin-top: 20px;
            text-align: center;
            margin: 5px;
            @media (max-width: 800px) {
              padding: 10px 10px;
            }
          `}
          type="button"
          disabled={loggingIn}
          onClick={facebookLogin}
          title={strings.loginWithFacebook}
        >
          <Facebook />
        </button>
        <button
          css={`
            cursor: pointer;
            background-color: #4285f4;
            border-radius: 20px;
            padding: 15px 20px;
            color: #fff;
            margin-top: 20px;
            text-align: center;
            margin: 5px;
            @media (max-width: 800px) {
              padding: 10px 10px;
            }
          `}
          type="button"
          disabled={loggingIn}
          onClick={googleLogin}
          title={strings.loginWithGoogle}
        >
          <Google />
        </button>
        <TwitchLogin loggingIn={loggingIn} />
      </div>
    </div>
  );
};

export default Login;
