// @flow
import 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useCustomClaimRoleFromUser } from './utils/useCustomClaimRoleFromUser';
import strings from './strings';
import { fatLink } from './styles';
import { paperDark } from './colors';
import type { User } from './types';

const BecomePremiumBanner = ({
  user,
  loggingIn,
  text = strings.becomePremiumBannerBenefits,
}: {
  user: ?User,
  loggingIn: boolean,
  text: string,
}): React$Node => {
  const [role, loading] = useCustomClaimRoleFromUser(user);

  if (loggingIn || loading || role !== 'basic') {
    return null;
  }

  return (
    <Link
      to="/u/me"
      css={`
        margin: 1em auto;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 1em 2em;
        background-color: ${paperDark};
        border-radius: 200px;
        text-decoration: none;
        @media (max-width: 800px) {
          flex-direction: column;
          text-decoration: underline;
        }
      `}
    >
      {text}
      <span
        css={`
          ${fatLink};
          min-width: fit-content;
          padding: 1em 1em;
          margin: 0 0 0 1em;
          @media (max-width: 800px) {
            display: none;
          }
        `}
      >
        {strings.becomePremiumBannerAction}
      </span>
    </Link>
  );
};

export default BecomePremiumBanner;
