// @flow
import React, { useEffect, useRef, useMemo } from 'react';
import 'styled-components/macro';
import { useActiveIslands } from './useActiveIslands';
import { database } from './firebase';
import strings from './strings';
import { atom, useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import type { User } from './types';

type DodoCodes = {| [string]: { value: string } |};

const dodoCodes = atom<DodoCodes>({
  key: 'dodocodes',
  default: {},
});

const dodoToasts = atom({
  key: 'dodoToasts',
  default: [],
});

export const useDodoCodes = (): [DodoCodes, any] => {
  return useRecoilState(dodoCodes);
};

const TOAST_STATES = {
  VISIBLE: 'VISIBLE',
  DISMISSED: 'DISMISSED',
  NEW: 'NEW',
};

const NewDodoAvailable = ({ user, hidden }: { user: ?User, hidden: boolean }): React$Node => {
  const [dodocodes, setDodocodes] = useRecoilState(dodoCodes);
  const [, setToasts] = useRecoilState(dodoToasts);

  const [islands] = useActiveIslands();

  useEffect(() => {
    const dodoListener = async (snap) => {
      if (user == null) {
        return;
      }
      const isInvited = (
        await database.ref(`/islands/${snap.ref.key}/guests/${user.uid}`).once('value')
      ).exists();
      const didLeave = (
        await database.ref(`/islands/${snap.ref.key}/guests/${user.uid}/settled`).once('value')
      ).exists();
      const canRead = isInvited && !didLeave;

      setDodocodes((dodocodes) => ({
        ...dodocodes,
        [snap.ref.key]: canRead ? snap.val() : undefined,
      }));
    };

    islands.forEach((island) => {
      database.ref(`/dodocodes/${island.key}`).on('value', dodoListener);
      database.ref(`/dodocodes/${island.key}`).on('child_removed', dodoListener);
    });

    return () => {
      islands.forEach((island) => {
        database.ref(`/dodocodes/${island.key}`).off('value', dodoListener);
        database.ref(`/dodocodes/${island.key}`).off('child_removed', dodoListener);
      });
    };
  }, [islands, setDodocodes, user]);

  // $FlowFixMe[incompatible-use]
  const dodocodeValues = useMemo(
    () =>
      dodocodes != null
        ? Object.entries(dodocodes)
            .filter(([, x]) => x != null)
            .map(([key, { value }]: any) => ({ key, code: value }))
        : [],
    [dodocodes]
  );

  const deleting = useRef([]);
  // Add new dodocodes to toasts list
  useEffect(() => {
    setToasts((toasts) => {
      const newCodes = dodocodeValues
        .filter((code) => !toasts.find((t) => t.key === code.key))
        .filter((x) => x.code != null);
      return [
        ...toasts,
        ...newCodes.map((code) => {
          return {
            ...code,
            state: TOAST_STATES.VISIBLE,
            id: toast(
              <>
                {strings.newDodoCodeAvailable} <b>{code.code}</b>
              </>,
              {
                autoClose: false,
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => {
                  setToasts((toasts) => {
                    const filtered = toasts.filter((t) => t.key !== code.key);
                    if (deleting.current.includes(code.key)) {
                      deleting.current = deleting.current.filter((x) => x !== code.key);
                      return filtered;
                    }
                    return [...filtered, { ...code, id: null, state: TOAST_STATES.DISMISSED }];
                  });
                },
              }
            ),
          };
        }),
      ];
    });
  }, [dodocodeValues, setToasts]);

  useEffect(() => {
    setToasts((toasts) => {
      return toasts.filter((t) => {
        const exists = dodocodeValues
          .filter((x) => x.code != null)
          .map((x) => x.key)
          .includes(t.key);
        if (!exists) {
          deleting.current.push(t.key);
          toast.dismiss(t.id);
          return false;
        }
        return true;
      });
    });
  }, [dodocodeValues, setToasts]);

  return null;
};

export default NewDodoAvailable;
