import sortPremiumFirst from './sortPremiumFirst';
import sortBoostsFirst from './sortBoostsFirst';

export default function sortWaitingList(list) {
  return (
    list
      ?.sort((a, b) => (a.joined ?? 0) - (b.joined ?? 0))
      .sort(sortPremiumFirst)
      .sort(sortBoostsFirst) ?? []
  );
}
