// @flow
export const paper = '#fefbfb';
export const brown = '#765223';
export const border = '#877959';
export const rowAlt = '#D8D3B5';
export const paperDark = '#E5DEC2';
export const linkBg = '#F6C508';
export const linkColor = '#8b7004';
export const pale = '#F8F6E2';
export const bg = '#FBCEB8';
export const red = '#ff9595';
export const darkRed = '#c81709';
export const blueDodo = '#1990D0';
export const darkGray = '#262626';
export const white = '#ffffff';
export const black = '#000000';
export const palePink = '#fbceb7';
export const passportGreen = '#b3e2b5';
export const bestFriendsOrange = '#ffcfae';
export const reactionsPurple = '#ebc5ff';
export const joinQueueBlue = '#b4e4fe';
export const purple = '#c390f5';
export const catalogYellow = '#efe168';

export const darkPaper = '#131313';
export const darkPale = '#1F1F1F';
