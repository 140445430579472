// @flow
import { Component } from 'react';
import strings from './strings';

type State = { hasError: boolean, error: ?Error, errorInfo: any };

export default class ErrorBoundary extends Component<{ children: React$Node }, State> {
  state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error: string): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error(error, errorInfo);
    // You can also log the error to an error reporting service
    this.setState({ error, errorInfo });
  }

  render(): React$Node {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>{strings.somethingWentWrong}</h1>
          <p>{strings.theErrorHasBeenReported}</p>
          <p>{String(this.state.error)}</p>
        </>
      );
    }

    return this.props.children;
  }
}
