import { useEffect, useMemo } from 'react';
import { atom, useSetRecoilState, useRecoilValue } from 'recoil';
import { database } from './firebase';
import { useList } from 'react-firebase-hooks/database';

const EXPIRED_ISLAND_TIMEOUT = 2 * 60 * 60 * 1000;

const gerNearestMinute = () => {
  const t = new Date();
  t.setMilliseconds(0);
  t.setSeconds(0);
  return Number(t);
};

const islandsAtom = atom({
  key: 'islands',
  default: { islands: [], loading: true },
  dangerouslyAllowMutability: true,
});

export const useActiveIslandsListener = () => {
  const setIslands = useSetRecoilState(islandsAtom);

  const [islands = [], loading] = useList(
    database
      .ref('/islands')
      .orderByChild('lastOnline')
      .startAt(gerNearestMinute() - EXPIRED_ISLAND_TIMEOUT)
  );

  const validIslands = useMemo(
    () => islands.filter((island) => island.val().island != null),
    [islands]
  );

  useEffect(() => {
    setIslands({ islands: validIslands, loading: loading && validIslands.length === 0 });
  }, [validIslands, loading, setIslands]);
};

export const useActiveIslands = () => {
  const { islands, loading } = useRecoilValue(islandsAtom);
  return [islands, loading];
};
