import React, { useEffect, useState, forwardRef } from 'react';
import { css, keyframes, createGlobalStyle } from 'styled-components/macro';
import * as colors from './colors';
import useResizeAware from 'react-resize-aware';
import Footer from './Footer';
import { transparentize } from 'polished';

if ('visualViewport' in window) {
  document.documentElement.style.setProperty('--vvh', `${visualViewport.height / 100}px`);
  window.addEventListener(
    'resize',
    (evt) => {
      document.documentElement.style.setProperty('--vvh', `${visualViewport.height / 100}px`);
    },
    { passive: true }
  );
}

const fillAction = ({ unit }) => keyframes`
  0% {
    background-position-y: calc(${unit}px * 100), calc(${unit}px * 80), calc(${unit}px * 70);
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    background-position-y: calc(${unit}px * 50), calc(${unit}px * 0), calc(${unit}px * -10);
    opacity: 0;
  }
`;

const hideTitle = keyframes`
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  80.1% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const hideBackground = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Fill = forwardRef(
  (
    {
      loading,
      unit,
      fixedHeight,
      simpleLoading,
      loadingText,
      fullScreen,
      className,
      children,
      ...props
    },
    ref
  ) => (
    <div
      {...props}
      ref={ref}
      css={`
        position: relative;
        overflow: hidden;
        isolation: isolate;
        backface-visibility: hidden;
        background-color: ${colors.paper};
        ${!fullScreen
          ? css`
              max-width: 800px;
              min-height: 800px;
              width: 800px;
              margin: 25px auto;
              border-radius: 100px;
              padding: 50px;
            `
          : css`
              height: 100vh;
            `};

        display: block;
        flex: 1;

        @media (prefers-color-scheme: dark) {
          background-color: ${colors.darkPaper};
          color: #fff;
        }

        &::after {
          content: '${loadingText}';
          display: ${simpleLoading ? 'none' : 'block'};
          z-index: 2;
          font-size: 2em;
          position: absolute;
          top: 30%;
          left: 0;
          text-align: center;
          width: 100%;
          animation-name: ${hideTitle};
          animation-iteration-count: 1;
          animation-duration: 2s;
          animation-fill-mode: forwards;
          pointer-events: none;
        }

        &::after {
          visibility: ${loading ? 'visible' : 'hidden'};
        }

        @media (max-width: 800px) {
          ${!fullScreen &&
          css`
            margin: 10px;
            border-radius: 50px;
            padding: 50px 10px;
            @media (max-width: 800px) {
              padding: 20px 10px 30px 10px;
            }
          `}
          padding: 0px 10px;
          min-height: 500px;
          width: calc(100% - 20px);
          overflow: auto;
        }
      `}
      className={className}
    >
      <div
        css={`
          display: ${simpleLoading ? 'none' : 'block'};
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          animation-name: ${fillAction({ unit })};
          animation-iteration-count: 1;
          animation-timing-function: cubic-bezier(0.55, -0.15, 0.75, 1.35);
          animation-duration: 2s;
          animation-fill-mode: forwards;
          background-image: linear-gradient(to bottom, transparent 30%, #75d5c8 30%),
            url(${require('./images/wave.svg').default ?? require('./images/wave.svg')}),
            url(${require('./images/wave2.svg').default ?? require('./images/wave2.svg')});
          background-repeat: no-repeat;
          background-size: cover;
          pointer-events: none;
          background-position-y: calc(${unit}px * 100), calc(${unit}px * 80), calc(${unit}px * 70);
          visibility: ${loading ? 'visible' : 'hidden'};
        `}
      />
      {children}
    </div>
  )
);

export const Container = forwardRef(
  (
    {
      children,
      loadingText = 'My Dodo Link',
      loading,
      extendedAnimation,
      fixedHeight,
      fullScreen,
      ...props
    },
    ref
  ) => {
    const [simpleLoading, setSimpleLoading] = useState(true);
    useEffect(() => {
      const id = setTimeout(() => setSimpleLoading(false), 200);
      return () => clearTimeout(id);
    }, []);

    const [resizeListener, sizes] = useResizeAware();
    return (
      <>
        {!fullScreen && (
          <h1
            css={`
              align-self: center;
              margin: 20px 0 10px 0;
              opacity: ${loading && extendedAnimation ? 0 : 1};
              transition: opacity 0.3s ease-in-out;
              @media (max-height: 800px) and (max-width: 800px) {
                display: none;
              }
            `}
          >
            My Dodo Link
          </h1>
        )}
        <Fill
          unit={(sizes.height || 335) / 100}
          loading={loading}
          simpleLoading={simpleLoading}
          loadingText={loadingText}
          fixedHeight={fixedHeight}
          fullScreen={fullScreen}
          ref={ref}
          {...props}
        >
          {resizeListener}
          <div
            css={`
              position: absolute;
              z-index: 1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: ${colors.paper};

              @media (prefers-color-scheme: dark) {
                background-color: ${colors.darkPaper};
              }

              animation-name: ${hideBackground};
              animation-iteration-count: 1;
              animation-duration: 1.8s;
              animation-fill-mode: forwards;
              pointer-events: none;
              visibility: ${loading ? 'visible' : 'hidden'};
            `}
          />
          {children}
        </Fill>
        {!fullScreen && <Footer />}
      </>
    );
  }
);

export const fatLink = css`
  display: inline-flex;
  background-color: ${colors.linkBg};
  padding: 5px 10px;
  border-radius: 30px;
  color: ${colors.brown};
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  flex-shrink: 0;
  &:hover,
  &:active {
    filter: brightness(80%);
  }
  &:disabled,
  &[disabled] {
    filter: grayscale(0.8);
    cursor: default;
    &:hover,
    &:active {
      filter: grayscale(0.8);
    }
  }
  @media (max-width: 800px) {
    padding: 10px 15px;
    &.icon {
      padding: 5px 10px;
    }
  }
`;

export const InputBox = ({ label, children, className, horizontal }) => (
  <label
    className={className}
    css={`
      display: ${horizontal ? 'flex' : 'grid'};
      ${horizontal ? 'flex-direction: row' : ''};
      grid-template-rows: 1fr;
      grid-gap: 10px;
      margin-top: 1em;
    `}
  >
    <p>{label}</p>
    {children}
  </label>
);

export const arc = (color, hideNavigation = false) => css`
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    height: 210px;
    background-image: url('${require(`./images/arc-${color}.svg`).default ??
    require(`./images/arc-${color}.svg`)}');
    --arc-color: ${color};
    background-position: center ${hideNavigation ? '-90px' : 'top'};
    background-size: 800px;
    background-repeat: no-repeat;
  }
`;

export const GlobalStyle: any = createGlobalStyle`
  body {
    background-color: ${colors.bg};
    background-image: radial-gradient(#FCD8C8 15%, transparent 16%), radial-gradient(#FCD8C8 15%, transparent 16%);
    background-position: 0 0, 25px 25px;
    @media (prefers-color-scheme: dark) {
      background-image: linear-gradient(to right, #00000010, #00000010), radial-gradient(#FCD8C8 15%, transparent 16%), radial-gradient(#FCD8C8 15%, transparent 16%);
      background-position: 0 0, 0 0, 25px 25px;
    }
    background-size: 50px 50px;
  }
  a {
    color: ${colors.linkColor};
  }
  button {
    border:none;
    font-size: 1em;
    appearance: none;
    line-height: 1.7em;
    font-family: inherit;
    &:focus {
      outline: none;
    }
    display: inline-flex;
    background-color: ${colors.linkBg};
    padding: 5px 10px;
    border-radius: 30px;
    color: ${colors.brown};
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: filter 0.2s ease-in-out, transform ease-in 0.1s;
    border: 0;
    -webkit-text-fill-color: currentColor;
    &:hover, &:active {
      filter: brightness(80%);
    }
    &:disabled, &[disabled] {
      filter: grayscale(.8);
      cursor: default;
      color: ${transparentize(0.5, colors.black)}
    }
    &:active {
      transform: scale(0.9);
    }

    @media (max-width: 800px) {
      padding: 10px 15px;
    }
  }

  input:not([type=checkbox]):not([type=radio]), textarea, select {
    border:none;
    font-size: 1em;
    appearance: none;
    font-family: inherit;
    line-height: 1.7em;
    &:focus {
      outline: none;
    }
    position: relative;
    background-color: ${colors.pale};
    @media (prefers-color-scheme: dark) {
      background-color: ${colors.darkPale};
      color: #fff;
    }
    
    border-radius: 10px;
    padding: 10px 20px;
    border-bottom: 2px dashed ${colors.border};

    &::placeholder {
      -webkit-text-fill-color: gray;
    }

    &:disabled {
      filter: grayscale(1);
      opacity: 0.5;
      color: #c3c3c3;
    }
  }

  input[type=checkbox] {
    all: unset;
    position: relative;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background-color: ${colors.pale};
    border: 2px solid ${colors.border};
    margin: 0 5px;
    @media (prefers-color-scheme: dark) {
      background-color: ${colors.darkPale};
    }
    &:checked {
      background-color: ${colors.linkBg}
    }
    &:checked::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '✓'
    }
  }

  textarea {
    @media (max-width: 800px) {
      min-height: 7em;
    }
  }

  hr {
    border-top: 0;
    margin: 50px 0;
    border-bottom: 2px dashed ${colors.border};
    width: 100%;
  }
`;
