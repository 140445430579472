import { StrictMode } from 'react';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

const Providers = ({ children, useMemoryRouter, hideToasts = false }) => {
  const Router = useMemoryRouter ? MemoryRouter : BrowserRouter;
  return (
    <StrictMode>
      <ErrorBoundary>
        <Router>
          <RecoilRoot>
            {!hideToasts && <ToastContainer />}
            {children}
          </RecoilRoot>
        </Router>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default Providers;
