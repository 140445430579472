// @flow
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import { Container, fatLink } from './styles';
import Icon from './Icon';
import Login from './Login';
import strings from './strings';
import getInterfaceLanguage from './getLang';
import IslandsList from './IslandsList';
import { LogOut, Facebook, User } from 'react-feather';
import 'styled-components/macro';
import type { User as UserType } from './types';

const lang = getInterfaceLanguage().split('-')[0];

const Home = ({
  user,
  loggingIn,
  fillLoading,
  showLoadingAnimation,
  setLoading,
}: {
  user: ?UserType,
  loggingIn: boolean,
  fillLoading: boolean,
  showLoadingAnimation: boolean,
  setLoading: (boolean) => void,
}): React$Node => {
  return (
    <Container
      loading={fillLoading}
      loadingText={showLoadingAnimation === false ? strings.loading : undefined}
      extendedAnimation={showLoadingAnimation}
      fixedHeight
      css="display: flex; flex-direction: column;"
    >
      {user == null && loggingIn === false && (
        <div
          css={`
            align-self: center;
            margin: -20px 0 20px;
            text-align: center;
            @media (max-width: 800px) {
              margin: -20px 0 20px;
            }
          `}
        >
          <Login loggingIn={loggingIn} />
        </div>
      )}

      {user && (
        <div
          css={`
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            align-items: stretch;
            align-self: stretch;
            height: 44px;
            @media (max-width: 800px) {
              padding: 0 10px;
            }
          `}
        >
          {lang === 'it' && (
            <a
              href="https://www.facebook.com/groups/768073463703253"
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              css={`
                ${fatLink};
                margin-right: auto;
                background-color: #2d88ff;
                color: #fff;
                align-items: center;
              `}
            >
              <Facebook />
            </a>
          )}
          <Link
            css={`
              ${fatLink};
              display: flex;
              align-items: center;
              margin-right: 1em;
            `}
            to={user != null ? '/island-manager' : '#'}
            disabled={user == null}
            className="icon"
          >
            <span css="margin: -8px -5px;">
              <Icon icon="palm" size={32} />
            </span>
            <span css={'margin-left: 10px;  @media (max-width: 800px) { display: none; }'}>
              {strings.manageIsland}
            </span>
          </Link>
          <Link
            to={`/u/${user.uid}`}
            className="icon"
            css={`
              ${fatLink};
              margin-right: 10px;
              align-items: center;
            `}
          >
            <User />
          </Link>
          <button
            className="icon"
            onClick={() =>
              // eslint-disable-next-line no-restricted-globals
              confirm(strings.logoutConfirmation) && firebase.auth().signOut()
            }
            css={`
              ${fatLink};
              align-items: center;
              background-color: #f74040;
              color: #fff;
            `}
          >
            <LogOut />
          </button>
        </div>
      )}

      <IslandsList user={user} setLoading={setLoading} loggingIn={loggingIn} />
    </Container>
  );
};
export default Home;
