import { useEffect, useCallback } from 'react';
import { auth } from './firebase';
import { atom, useRecoilState } from 'recoil';
import strings from './strings';
import 'styled-components/macro';
import { Container } from './styles';
import { useHistory } from 'react-router-dom';

const errorState = atom({
  key: 'twitchLoginError',
  default: null,
});

/**
 * Returns the value of the given URL query parameter.
 */
function getURLParameter(name) {
  return (
    decodeURIComponent(
      (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [
        null,
        '',
      ])[1].replace(/\+/g, '%20')
    ) || null
  );
}

const TwitchLogin = () => {
  const linkToken = localStorage.getItem('link-token');
  const [error, setError] = useRecoilState(errorState);
  const history = useHistory();

  /**
   * This callback is called by the JSONP callback of the 'token' Firebase Function with the Firebase auth token.
   */
  window.tokenReceived = useCallback(
    (data) => {
      if (data.token) {
        localStorage.removeItem('link-token');
        auth.signInWithCustomToken(data.token).then(function () {});
        history.push('/');
      } else {
        console.error(data);
        if (data.error === 'Error: The email address is already in use by another account.') {
          setError(strings.emailAlreadyInUse);
        } else {
          setError(data.error);
        }
      }
    },
    [setError, history]
  );

  useEffect(() => {
    var code = getURLParameter('code');
    var state = getURLParameter('state');
    var error = getURLParameter('error');
    if (error) {
      document.body.innerText = 'Error back from the Instagram auth page: ' + error;
    } else if (!code) {
      // Start the auth flow.
      window.location.href = '/_twitchRedirect';
    } else {
      // Use JSONP to load the 'token' Firebase Function to exchange the auth code against a Firebase custom token.
      const script = document.createElement('script');
      script.type = 'text/javascript';
      // This is the URL to the HTTP triggered 'token' Firebase Function.
      // See https://firebase.google.com/docs/functions.
      var tokenFunctionURL = '/_twitchToken';
      script.src =
        tokenFunctionURL +
        '?code=' +
        encodeURIComponent(code) +
        '&state=' +
        encodeURIComponent(state) +
        '&callback=' +
        'window.tokenReceived' +
        '&link_token=' +
        encodeURIComponent(linkToken);
      document.head.appendChild(script);
    }
  }, [linkToken]);

  return (
    <Container>
      <h2 css="text-align: center; align-self: center;">
        {error ?? strings.pleaseWaithWhileLoggingIn}
      </h2>
    </Container>
  );
};

export default TwitchLogin;
