import firebase from 'firebase/app';
import 'firebase/database';

export default async function getTotalGuests(islandId) {
  if (islandId == null) {
    return 0;
  }

  const guestsSnap = await firebase
    .database()
    .ref(`islands/${islandId}/guests`)
    .once('value');
  const waitingSnap = await firebase
    .database()
    .ref(`waitinglists/${islandId}`)
    .once('value');

  return (
    Object.keys(guestsSnap.val() ?? {}).length +
    Object.keys(waitingSnap.val() ?? {}).length
  );
}
