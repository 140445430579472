const icons = {
  all: require('./all.svg').default ?? require('./all.svg'),
  bell: require('./bell.png').default ?? require('./bell.png'),
  celeste: require('./celeste.png').default ?? require('./celeste.png'),
  'nook-ticket': require('./nook-ticket.png').default ?? require('./nook-ticket.png'),
  palm: require('./palm.png').default ?? require('./palm.png'),
  scheme: require('./scheme.png').default ?? require('./scheme.png'),
  schemes: require('./schemes.png').default ?? require('./schemes.png'),
  'star-yellow': require('./star-yellow.png').default ?? require('./star-yellow.png'),
  'star-purple': require('./star-purple.png').default ?? require('./star-purple.png'),
  turnip: require('./turnip.png').default ?? require('./turnip.png'),
  'gift-red': require('./gift-red.png').default ?? require('./gift-red.png'),
  island: require('../images/island.svg').default ?? require('../images/island.svg'),
  other: require('./other.svg').default ?? require('./other.svg'),
  apple: require('./apple.png').default ?? require('./apple.png'),
  pear: require('./pear.png').default ?? require('./pear.png'),
  cherry: require('./cherry.png').default ?? require('./cherry.png'),
  peach: require('./peach.png').default ?? require('./peach.png'),
  coconut: require('./coconut.png').default ?? require('./coconut.png'),
  orange: require('./orange.png').default ?? require('./orange.png'),
  furniture: require('./furniture.png').default ?? require('./furniture.png'),
  postbox: require('./postbox.png').default ?? require('./postbox.png'),
};
export default icons;
