import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import strings from './strings';
import { useActiveIslands } from './useActiveIslands';

const displayNotification = (message) => {
  if ('Notification' in window && Notification.permission === 'granted') {
    navigator.serviceWorker.getRegistration().then(function (reg) {
      reg &&
        typeof reg.showNotification === 'function' &&
        reg.showNotification(message);
    });
  }
};
const usePushNotifications = ({ user }) => {
  const [islands] = useActiveIslands();

  useEffect(() => {
    const dodoListener = (snap) => {
      if (!snap.exists() || user == null) {
        return;
      }
      firebase
        .database()
        .ref(`/notifications/${snap.key}/${user.uid}`)
        .once('value', (notification) => {
          if (!notification.exists() || notification.val() !== true) {
            displayNotification(
              `${strings.newDodoCodeAvailable} ${snap.val().value}`
            );
            firebase
              .database()
              .ref(`/notifications/${snap.key}/${user.uid}`)
              .set(true);
          }
        });
    };
    islands.forEach((island) => {
      firebase
        .database()
        .ref(`/dodocodes/${island.key}`)
        .on('value', dodoListener);
    });
    return () => {
      islands.forEach((island) => {
        firebase
          .database()
          .ref(`/dodocodes/${island.key}`)
          .off('value', dodoListener);
      });
    };
  }, [islands, user]);
};

export default usePushNotifications;
