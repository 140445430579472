import React, { useState, useCallback, useEffect } from 'react';
import 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useObjectVal } from 'react-firebase-hooks/database';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import { database, firestore, auth } from './firebase';
import { Container, fatLink, arc } from './styles';
import strings from './strings';
import { useCustomClaimRoleFromUser } from './utils/useCustomClaimRoleFromUser';
import Icon from './Icon';
import { Table, ScrollableTableContainer } from './ui/Table';
import { InputBox } from './styles';
import { TwitchLogin } from './Login';

import * as colors from './colors';
import NewDodoAvailable from './NewDodoAvailable';
import { shake } from './utils/animations';

const User = ({ uid, currentUser, loggingIn }) => {
  if (uid === 'me') {
    uid = currentUser?.uid;
  }

  useEffect(() => {
    auth.currentUser?.getIdToken().then((token) => localStorage.setItem('link-token', token));
  }, []);

  const [role] = useCustomClaimRoleFromUser(currentUser);
  const [twitchData] = useCustomClaimRoleFromUser(currentUser, 'twitch');

  const [currentUserData] = useObjectVal(database.ref(`users/${currentUser?.uid}`));
  const [player, playerLoading] = useObjectVal(database.ref(`users/${uid}`));
  const [island, islandLoading] = useObjectVal(
    currentUser != null ? database.ref(`islands/${currentUser?.uid}`) : null
  );
  const [banlist, banlistLoading] = useObjectVal(
    island != null ? database.ref(`banlists/${island.banlist}`) : null
  );

  const isPageOwner = currentUser?.uid === uid;

  useEffect(() => {
    if (
      island?.banlist != null &&
      island?.banlist !== 'default' &&
      banlist == null &&
      banlistLoading !== true &&
      currentUser != null
    ) {
      database.ref(`banlists/${island.banlist}/moderators/${currentUser.uid}`).set(true);
    }
  }, [banlist, island?.banlist, currentUser, banlistLoading]);

  useEffect(() => {
    if (currentUser != null && role === 'premium') {
      database.ref(`users/${currentUser.uid}`).update({ role });
    }
  }, [currentUser, role]);

  const isModerator = banlist
    ? Object.entries(banlist.moderators || {}).some(
        ([mid, isMod]) => currentUser?.uid === mid && isMod
      )
    : false;

  const isUserBanned = banlist
    ? Object.entries(banlist.bans || {}).some(([mid, isBanned]) => uid === mid && isBanned)
    : false;

  const ban = useCallback(() => {
    if (currentUser == null || currentUserData == null) return;

    const ref = database.ref(`banlists/${island.banlist}/bans/${uid}`);

    if (isUserBanned) {
      const details = prompt(strings.provideUnbanReason);
      ref.remove();
      firestore.collection('userlogs').add({
        uid,
        firedBy: currentUser.uid,
        firedByName: currentUserData.username,
        action: 'unbanned',
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        banlist: island.banlist,
        details,
      });
    } else {
      const details = prompt(strings.provideBanReason);
      database.ref(`islands/${currentUser.uid}/guests/${uid}`).remove();
      database.ref(`waitinglists/${currentUser?.uid}/${uid}`).remove();
      ref.set(true);
      firestore.collection('userlogs').add({
        uid,
        firedBy: currentUser.uid,
        firedByName: currentUserData.username,
        action: 'banned',
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        banlist: island.banlist,
        details,
      });
    }
  }, [isUserBanned, island?.banlist, uid, currentUser, currentUserData]);

  const goToCustomerPortal = async (evt) => {
    evt.target.disabled = true;

    const functionRef = firebase
      .app()
      .functions('europe-west2')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  };

  const [userlogs, setUserlogs] = useState([]);
  useEffect(() => {
    if (!isModerator) return;

    (async () => {
      const querySnapshots = await firebase
        .firestore()
        .collection('userlogs')
        .where('uid', '==', uid)
        .orderBy('timestamp', 'desc')
        .limit(300)
        .get();

      let logs = [];
      querySnapshots.forEach((snap) => {
        logs.push(snap.data());
      });
      setUserlogs(logs);
    })();
  }, [uid, isModerator]);

  return (
    <Container
      loading={islandLoading || playerLoading || banlistLoading || loggingIn}
      loadingText={strings.loading}
      css={`
        ${arc('passportGreen')}
      `}
    >
      <NewDodoAvailable user={currentUser} />

      <Link css={fatLink} to="/">
        {strings.backToHomePage}
      </Link>

      <h2
        css={`
          margin-top: 25px;
          margin-bottom: 50px;
          display: flex;
          align-items: center;
          align-self: stretch;
          justify-content: center;
          color: ${colors.black};
        `}
      >
        {player != null && (
          <>
            {player.role === 'premium' && <Icon icon="star-yellow" css="width: 32px;" />}
            {player.username != null && player.island != null
              ? `${strings.formatString(strings.nameFromIsland, player.username, player.island)}`
              : player.firstName != null || player.lastName != null
              ? `${player.firstName ?? ''} ${player.lastName ?? ''}`
              : currentUser.displayName ?? `${strings.welcome}!`}
          </>
        )}
      </h2>

      {isPageOwner && (
        <>
          <h3 css="margin-top: 1em; align-self: stretch; text-align: center; margin-bottom: 20px;">
            {role === 'premium' ? strings.youArePremium : strings.becomePremiumBannerAction}
          </h3>
          <p css="margin-bottom: 1em">{strings.youArePremiumDescription}</p>

          {role === 'basic' && (
            <div
              css={`
                align-self: stretch;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: 800px) {
                  flex-direction: column;
                }
              `}
            >
              <Link
                css={`
                  ${fatLink};
                  display: flex;
                  align-items: center;
                  text-align: center;
                  margin: 0.5em;
                  padding: 1em 2em;
                  @media (max-width: 800px) {
                    min-height: 4em;
                  }
                `}
                to={role === 'basic' ? 'me/subscribe-premium/monthly' : '#'}
              >
                <span>{strings.subscribeToPremiumMonthly}</span>
              </Link>
              <span>{strings.or}</span>
              <Link
                css={`
                  ${fatLink};
                  display: flex;
                  align-items: center;
                  text-align: center;
                  margin: 0.5em;
                  padding: 1em 2em;
                  animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                  animation-iteration-count: 3;
                  transform: translate3d(0, 0, 0);
                  backface-visibility: hidden;
                  perspective: 1000px;
                  @media (max-width: 800px) {
                    min-height: 4em;
                  }
                  &:hover,
                  &:focus {
                    animation-iteration-count: infinite;
                  }
                `}
                to={role === 'basic' ? 'me/subscribe-premium/yearly' : '#'}
              >
                <span>{strings.subscribeToPremiumYearly}</span>
              </Link>
            </div>
          )}

          <div
            css={`
              display: flex;
              align-self: stretch;
              justify-content: flex-end;
              margin-top: 20px;
            `}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" css="margin: 0.5em;" onClick={goToCustomerPortal}>
              {strings.manageSubscription}
            </a>
          </div>

          <InputBox label={strings.yourProfileLink} css="width: 100%">
            <input
              type="text"
              readOnly
              value={`https://${window.location.host}/u/${uid}`}
              onClick={(evt) => {
                evt.target.select();
                document.execCommand('copy');
              }}
            />
          </InputBox>

          {uid != null && !uid.startsWith('twitch:') && twitchData == null && (
            <TwitchLogin css="margin-top: 2em;" loggingIn={loggingIn}>
              {strings.linkYourTwitchAccount}
            </TwitchLogin>
          )}
        </>
      )}

      {isModerator && player != null && (
        <>
          <p css="margin-bottom: 1em; margin-top: 3em">
            <strong>{strings.realName}:</strong>{' '}
            {player.firstName == null && player.lastName == null
              ? strings.realNameNotAvailable
              : `${player.firstName} ${player.lastName}`}{' '}
            (Facebook ID:
            {player.facebookId ?? '?'} / Google ID: {player.googleId ?? '?'})
          </p>
          <p css="margin-bottom: 1em;">
            <strong>ISP:</strong> {player.isp ?? '???'}
          </p>
        </>
      )}

      {isUserBanned && <p css="margin: 2em 0">{strings.youBannedUser}</p>}
      {isModerator && (
        <div
          css={`
            background: ${colors.red};
            padding: 1em;
            border-radius: 20px;
            margin: 2em 0;
          `}
        >
          <p>{strings.waitingListModeratorDescription}</p>
          <button css="margin-top: 1em" type="button" onClick={ban}>
            {isUserBanned ? strings.unban : strings.ban}
          </button>
        </div>
      )}
      {isModerator && (
        <>
          <hr />
          <ScrollableTableContainer css="max-width: 100%;">
            <Table className="large even-cols" css="min-width: 600px;">
              <thead>
                <tr>
                  <th>{strings.datetime}</th>
                  <th>{strings.logAction}</th>
                  <th>{strings.logData}</th>
                  <th>{strings.details}</th>
                </tr>
              </thead>
              <tbody>
                {userlogs.map(
                  ({ iid, timestamp, island, action, firedBy, firedByName, details }, index) => (
                    <tr key={index}>
                      <td>{new Date(timestamp.seconds * 1000).toLocaleString()}</td>
                      <td>{strings[`logaction:${action}`] ?? action}</td>
                      <td>
                        {(() => {
                          switch (action) {
                            case 'invited':
                            case 'joinedQueue':
                            case 'leftQueue':
                            case 'kicked':
                              return (
                                <>
                                  {strings.islandName}: <Link to={`/u/${iid}`}>{island}</Link>
                                </>
                              );
                            case 'banned':
                              return (
                                <Link to={`/u/${firedBy}`}>
                                  {strings.formatString(strings.bannedBy, firedByName)}
                                </Link>
                              );
                            case 'unbanned':
                              return (
                                <Link to={`/u/${firedBy}`}>
                                  {strings.formatString(strings.unbannedBy, firedByName)}
                                </Link>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </td>
                      <td>{details}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </ScrollableTableContainer>
        </>
      )}
    </Container>
  );
};

export default User;
