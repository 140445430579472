// @flow
export default function getInterfaceLanguage(): string {
  const defaultLang = 'en-US';
  if (typeof navigator === 'undefined') {
    return defaultLang;
  }
  const nav = navigator; // eslint-disable-line no-undef
  if (nav) {
    if (nav.language) {
      return nav.language;
    }
    if (!!nav.languages && !!nav.languages[0]) {
      return nav.languages[0];
    }
    // $FlowFixMe[prop-missing]
    if (typeof nav.userLanguage === 'string') {
      return nav.userLanguage;
    }

    // $FlowFixMe[prop-missing]
    if (typeof nav.browserLanguage === 'string') {
      return nav.browserLanguage;
    }
  }
  return defaultLang;
}
