import { lazy, Suspense } from 'react';
import { Container, arc } from '../styles';
import strings from '../strings';

const LazyIslandManager = lazy(() => import('./IslandManager'));

export default function IslandManager(props) {
  return (
    <Suspense
      fallback={<Container loading loadingText={strings.loading} css={arc('catalogYellow')} />}
    >
      <LazyIslandManager {...props} />
    </Suspense>
  );
}
