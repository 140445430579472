import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY, localized } from './config';
export const MONTHLY_PLAN = 'monthly';
export const YEARLY_PLAN = 'annual';

export function usePremiumCheckoutRedirect({ plan = MONTHLY_PLAN }) {
  const [user] = useAuthState(firebase.auth());

  useEffect(() => {
    (async () => {
      if (user == null) return;

      const docRef = await firebase
        .firestore()
        .collection('customers')
        .doc(user.uid)
        .collection('checkout_sessions')
        .add({
          price:
            plan === MONTHLY_PLAN
              ? localized.stripePremiumMonthlyPrice
              : localized.stripePremiumAnnualPrice,
          success_url: `${window.location.origin}/u/me`,
          cancel_url: `${window.location.origin}/u/me`,
          allow_promotion_codes: true,
          customerEmail: user.email,
        });

      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

          window.gtag?.('event', 'subscription', {
            event_category: 'monetization',
            event_label: `${MONTHLY_PLAN} Subscription`,
          });

          stripe.redirectToCheckout({ sessionId });
        }
      });
    })();
  }, [plan, user]);
}
