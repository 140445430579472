// @flow
import 'styled-components/macro';
import strings from './strings';

const Footer = (): React$Node => (
  <div css=" text-align: center; margin: 0 5px 5px 5px;">
    <a href="https://fezvrasta.github.io" rel="noreferer noopener">
      ©{new Date().getFullYear()}
    </a>
    {strings.lang === 'it' && (
      <>
        &nbsp;|&nbsp;
        <a href={`/${strings.lang}-faq.html`}>FAQ</a>
      </>
    )}
    &nbsp;|&nbsp;
    <a href={`/${strings.lang}-privacy.html`}>Privacy Policy</a>
    &nbsp;|&nbsp;
    <a href={`/tos.html`}>Terms of Service</a>
  </div>
);

export default Footer;
