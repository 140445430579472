import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { database } from '../firebase';
import { useList, useObject, useObjectVal } from 'react-firebase-hooks/database';
import { keyframes } from 'styled-components/macro';
import * as colors from '../colors';
import Icon from '../Icon';
import strings from '../strings';
import icons from '../icons';
import CategoriesSelector, { IconBox } from '../CategoriesSelector';
import NewDodoAvailable from '../NewDodoAvailable';
import { useActiveIslands } from '../useActiveIslands';
//import UpcomingIslands from './UpcomingIslands';
import sortWaitingList from '../utils/sortWaitingList';
import BecomePremiumBanner from '../BecomePremiumBanner';

const slide = keyframes`
  100% {
    background-position: -100% 100%;
  }
`;

const iconNames = ['all', 'turnip', 'celeste', 'gift-red', 'scheme', 'other'];

const STALE_ISLAND_TIMEOUT = 10 * 60 * 1000;

const isIslandStale = (lastOnline) =>
  lastOnline === true ? false : lastOnline < Date.now() - STALE_ISLAND_TIMEOUT;

const IslandRow = ({ island, user }) => {
  const history = useHistory();
  const islandVal = island.val();
  const [waitingList] = useList(database.ref(`/waitinglists/${island.key}`));
  const [joinedList] = useObjectVal(database.ref(`/waitinglists/${island.key}/${user?.uid}`));
  const [dodocode] = useObjectVal(database.ref(`/dodocodes/${island.key}`));
  const [isSettled] = useObject(database.ref(`/islands/${island.key}/guests/${user?.uid}/settled`));
  const [banned] = useObjectVal(
    database.ref(`/banlists/${island.child('banlist').val()}/bans/${user?.uid}`)
  );

  const beforeYou =
    user != null
      ? sortWaitingList(waitingList.map((x) => ({ ...x.val(), key: x.key }))).findIndex(
          (x) => x.key === user.uid
        )
      : -1;

  const total = Object.keys(islandVal.guests || {}).length + waitingList.length;
  const isFull = islandVal.maxTotalGuests > 0 && total >= islandVal.maxTotalGuests;
  const isClosed = islandVal.lineClosed;

  if (banned) {
    return null;
  }

  return (
    <div
      key={island.key}
      tabIndex={0}
      role="link"
      onClick={() => history.push(`/is/${islandVal.slug ?? island.key}`)}
      css={`
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border-bottom: 2px dashed ${colors.border};
        &:last-of-type {
          border-bottom: 0;
        }
        padding: 5px 0;
        &::after {
          border-radius: 100px;
          content: '';
          position: absolute;
          z-index: 0;
          top: 5px;
          right: 5px;
          bottom: 5px;
          left: 5px;
          background-image: repeating-linear-gradient(
            -45deg,
            #f9c471,
            #f9c471 10px,
            #f7d178 10px,
            #f7d178 20px
          );
          background-size: 200% 200%;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
          animation: ${slide} 25s linear infinite;
        }
        &:focus,
        &:hover {
          outline: 0;
          &::after {
            opacity: 1;
          }
        }
      `}
    >
      <div
        css={`
          position: relative;
          z-index: 1;
          display: inline-grid;
          grid-template-areas:
            'line icon state'
            'name icon state';
          grid-template-columns: 1fr auto 1fr;
          grid-template-rows: 1fr 1fr;
          grid-gap: 5px;
          flex-shrink: 0;
          flex: 1;
        `}
      >
        <div
          css={`
            grid-area: line;
            align-items: flex-end;
            justify-content: flex-end;
            display: flex;
          `}
        >
          <div
            css={`
              background-color: ${colors.paper};
              @media (prefers-color-scheme: dark) {
                background-color: ${colors.darkPaper};
              }
              border-radius: 20px;
              padding: 0 10px;
            `}
          >
            {(() => {
              if (beforeYou === 0 && dodocode == null) {
                return strings.waitingForCode;
              }
              if (beforeYou !== -1) {
                return `${beforeYou} ${strings.beforeYou}`;
              }
              if (islandVal.maxTotalGuests > 0 && total >= islandVal.maxTotalGuests) {
                return strings.fullLine;
              }
              return `${waitingList.length} ${strings.inLine}`;
            })()}
          </div>
        </div>
        <div css="grid-area: name; text-align: right;">
          <Icon
            icon="palm"
            size={16}
            css="display: inline-block; vertical-align: middle; margin-right: 5px;"
          />
          {islandVal.island}
        </div>
        <div
          css={`
            grid-area: state;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 0;
          `}
        >
          <div css="font-size: 1.3em; text-transform: capitalize;">
            {dodocode && isSettled
              ? dodocode.value
              : joinedList
              ? strings.inLine
              : isClosed
              ? strings.isClosed
              : isFull
              ? strings.fullLine
              : strings.reserve}
          </div>
          <div
            css={`
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `}
          >
            {(() => {
              switch (island.val().icon) {
                case 'scheme':
                  return strings.formatString(strings.schemeX, <b>{island.val().goodName}</b>);
                case 'furniture':
                  return strings.formatString(strings.furnitureX, <b>{island.val().goodName}</b>);
                case 'turnip':
                  return (
                    <>
                      {strings[`${island.val().exchangeType}X`] != null
                        ? strings.formatString(
                            strings[`${island.val().exchangeType}X`],
                            <b>{strings[island.val().icon]}</b>
                          )
                        : strings[island.val().icon]}
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
        <div css="grid-area: icon; display: flex; align-items: center;">
          <div
            css={`
              position: relative;
              width: 60px;
              height: 60px;
              background-color: ${colors.pale};
              @media (prefers-color-scheme: dark) {
                background-color: ${colors.darkPale};
              }

              border-radius: 50%;
              background-image: url(${icons[island.val().icon]});
              background-size: 80% 80%;
              background-repeat: no-repeat;
              background-position: ${island.val().icon === 'turnip' ? '70%' : 'center'} center;
              margin: 20px;
              filter: grayscale(${isIslandStale(island.child('lastOnline').val()) ? 1 : 0});
              @media (max-width: 800px) {
                margin: 20px 5px;
              }
            `}
          >
            <Icon
              icon="bell"
              size={32}
              css={`
                display: ${island.val().price === 0 ? 'none' : 'block'};
                position: absolute;
                bottom: -4px;
                right: -4px;
              `}
            />
            <div
              css={`
                position: absolute;
                width: 100%;
                bottom: -1em;
                text-align: center;
              `}
            >
              {island.val().price || strings.free}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IslandsList = ({ user, setLoading, loggingIn }) => {
  const [filter, setFilter] = useState({ icon: 'all' });
  const [islands, islandsLoading] = useActiveIslands();

  useEffect(() => {
    setLoading(islandsLoading);
  }, [islandsLoading, setLoading]);

  const onSetFilter = useCallback(
    ({ target: { value } }) => setFilter((icon) => ({ icon: value })),
    []
  );

  const filteredIslands = useMemo(
    () =>
      [...new Set(islands.map((item) => item.key))]
        .map((key) => islands.find((item) => item.key === key))
        .filter((island) =>
          filter.icon === 'other'
            ? !iconNames.includes(island.child('icon').val())
            : filter.icon !== 'all'
            ? island.child('icon').val() === filter.icon
            : true
        )
        .sort((a, b) => a.created - b.created)
        .sort((a) => {
          if (isIslandStale(a.child('lastOnline').val())) {
            return 1;
          } else {
            return -1;
          }
        }),
    [filter.icon, islands]
  );

  return (
    <>
      <NewDodoAvailable user={user} />
      <div
        css={`
          overflow: hidden;
          flex: 1;
          min-height: 0;
          isolation: isolate;
          background-color: ${colors.pale};
          @media (prefers-color-scheme: dark) {
            background: ${colors.darkPale};
          }
          padding: 20px;
          padding-top: 0;
          border-radius: 20px;
          align-self: stretch;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr;
          align-items: stretch;
          @media (max-width: 800px) {
            padding: 10px 5px;
            padding-top: 0;
          }
        `}
      >
        <div
          css={`
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            margin: 0 -20px;
            @media (max-width: 800px) {
              margin: 0 -5px;
            }
            &::after {
              background-image: linear-gradient(-45deg, ${colors.pale} 16px, transparent 0),
                linear-gradient(45deg, ${colors.pale} 16px, transparent 0);

              @media (prefers-color-scheme: dark) {
                background-image: linear-gradient(-45deg, ${colors.darkPale} 16px, transparent 0),
                  linear-gradient(45deg, ${colors.darkPale} 16px, transparent 0);
              }
              background-repeat: repeat-x;
              background-size: 32px 32px;
              content: ' ';
              display: block;
              position: absolute;
              bottom: 0px;
              left: 0px;
              width: 100%;
              height: 32px;
            }
          `}
        >
          <CategoriesSelector
            island={filter}
            size={80}
            disabled={false}
            onChange={onSetFilter}
            css={`
              overflow: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fcceb8;
              padding: 20px 10px 30px 10px;
              position: relative;
              background-image: linear-gradient(to right, #fcceb8b3, #fcceb8b3),
                url(${require('../images/items-pattern.png').default});
              @media (prefers-color-scheme: dark) {
                background-image: linear-gradient(to right, #00000020, #00000020),
                  linear-gradient(to right, #fcceb8b3, #fcceb8b3),
                  url(${require('../images/items-pattern.png').default});
              }
              background-size: 133px 133px;

              @media (max-width: 800px) {
                justify-content: stretch;
                padding: 20px 5px 30px 5px;
              }

              ${IconBox} {
                border-radius: 50px;
                margin: 0 -5px;

                @media (max-width: 800px) {
                  margin: 0 -8px;
                }

                & > div {
                  filter: grayscale(1);
                  background-size: 70% 70%;
                }

                &:first-of-type {
                  margin-left: auto;
                }
                &:last-of-type {
                  margin-right: auto;
                }
              }

              input:checked + ${IconBox} > div {
                filter: grayscale(0);
                background-size: 80% 80%;
              }
            `}
            noCheckedStyle
            iconNames={iconNames}
          />
        </div>

        <div
          css={`
            overflow: auto;
            max-height: 740px;
            @media (max-width: 800px) {
              max-height: auto;
            }
            @media (max-width: 500px) {
              font-size: 12px;
            }
          `}
        >
          {/*<UpcomingIslands user={user} islands={islands} /> */}
          <BecomePremiumBanner user={user} loggingIn={loggingIn} />
          {islandsLoading ? (
            <div css="text-align: center; margin-top: 100px;">{strings.loading}...</div>
          ) : filteredIslands.length === 0 ? (
            <div css="text-align: center; margin-top: 100px;">{strings.noIslandsAvailable}</div>
          ) : (
            filteredIslands.map((island, index) => (
              <IslandRow island={island} user={user} key={island.key} />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default IslandsList;
