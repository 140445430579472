import React, { useEffect, useState, useRef } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';
import { toast } from 'react-toastify';
import 'styled-components/macro';
import strings from './strings';

const updateServiceWorker = (serviceWorkerRegistration) => {
  const registrationWaiting = serviceWorkerRegistration.waiting;
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    registrationWaiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const Message = ({ reg }) => {
  const interval = useRef();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    interval.current = setInterval(() => {
      setCountdown((count) => count - 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(interval.current);
      updateServiceWorker(reg);
    }
  }, [countdown, reg]);

  return (
    <div
      css={`
        @media (max-width: 800px) {
          display: flex;
          flex-direction: column;
        }
      `}
    >
      {strings.formatString(strings.appUpdateAvailable, countdown)}

      <button
        onClick={() => updateServiceWorker(reg)}
        css={`
          margin-left: 1em;
          @media (max-width: 800px) {
            align-self: flex-end;
            margin-top: 1em;
          }
        `}
      >
        {strings.updateAppNow}
      </button>
    </div>
  );
};

export default function ServiceWorkerUI() {
  useEffect(() => {
    serviceWorker.register({
      onUpdate: (reg) => {
        toast(<Message reg={reg} />, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 10000,
          closeButton: false,
          onClick: () => updateServiceWorker(reg),
          draggable: false,
        });
      },
    });
  }, []);

  return null;
}
