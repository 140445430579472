// @flow
import { useCallback, useMemo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useObjectVal, useList } from 'react-firebase-hooks/database';
import { AlertOctagon, Twitch, Facebook, Youtube } from 'react-feather';
import { invert, transparentize, darken } from 'polished';

import firebase from 'firebase/app';
import { database, firestore, functions } from './firebase';
import 'firebase/database';
import styled from 'styled-components/macro';
import { Container, fatLink, InputBox, arc } from './styles';
import strings from './strings';
import Login from './Login';
import * as colors from './colors';
import NewDodoAvailable, { useDodoCodes } from './NewDodoAvailable';
import { useCustomClaimRoleFromUser } from './utils/useCustomClaimRoleFromUser';
import getTotalGuests from './utils/getTotalGuests';
import BecomePremiumBanner from './BecomePremiumBanner';
import sortWaitingList from './utils/sortWaitingList';

import type { User } from './types';

// const imgSpacing = (img) => {
//   const s = 60;
//   const x = 3;
//   return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${s}" height="${s}"><foreignObject x="${
//     s / x
//   }" y="${s / x}" width="${s / x}" height="${
//     s / x
//   }"><span xmlns="http://www.w3.org/1999/xhtml" style="width: ${s / x}px; height: ${
//     s / x
//   }px; background-color: rgba(255, 255, 255, 0.2); -webkit-mask-image: url(${img}); mask-image: url(${img}); -webkit-mask-size: ${
//     s / x
//   }px; mask-size: ${s / x}px; display: block;"></span></foreignObject></svg>`;
// };

// const GlobalStyle = createGlobalStyle`
//   html, body {
//   background-image: url('${(props) => imgSpacing(icons[props.icon])}'), url('${(props) =>
//   imgSpacing(icons[props.icon])}');
//   background-position: 0 0, 60px 60px;
//   background-size: 120px, 120px;
//   }
// `;

const getStreamService = (url) => {
  const { hostname } = new URL(url);
  switch (hostname?.replace('www.', '')) {
    case 'twitch.tv':
      return 'twitch';
    case 'youtu.be':
    case 'youtube.com':
      return 'youtube';
    case 'facebook.com':
      return 'facebook';
    default:
      return null;
  }
};
const serviceNames = {
  twitch: 'Twitch',
  facebook: 'Facebook',
  youtube: 'YouTube',
};

const serviceColors = {
  twitch: '#9146ff',
  facebook: '#4267b2',
  youtube: '#ff0000',
};

const serviceIcon = {
  twitch: Twitch,
  facebook: Facebook,
  youtube: Youtube,
};

const StreamButton = ({ href }) => {
  const service = getStreamService(href);
  if (service == null) {
    return null;
  }
  const Icon = serviceIcon[service];

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      css={`
        ${fatLink};
        padding: 1em 1.5em;
        color: ${colors.white};
        background-color: ${serviceColors[service]};
      `}
    >
      <Icon />
      <span css="margin-left: 0.5em;">
        {strings.formatString(strings.watchTheStream, serviceNames[service])}
      </span>
    </a>
  );
};

const getIsp = async () => {
  const args = 'YXBpS2V5PThmNzAwYjllNzM0MDRhNjBhYWExNzZmOTJhYzZjMTI2';
  let res;
  try {
    res = await fetch(`https://api.ipgeolocation.io/ipgeo?${atob(args)}`);
  } catch (err) {
    console.log('ISP fetch error');
    return 'Not available';
  }
  const json = await res.json();
  return json.isp;
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const useWaitingList = ({
  user,
  slug,
  loggingIn,
}: {
  user: ?User,
  slug: string,
  loggingIn: boolean,
}): any => {
  const history = useHistory();

  const [role] = useCustomClaimRoleFromUser(user);

  const [_iid] = useObjectVal(database.ref(`slugs/${slug}`));
  const iid = _iid ?? slug;

  const [currentUserData, loadingCurrentUserData] = useObjectVal(
    user != null ? database.ref(`users/${user.uid ?? 'undefined'}`) : null
  );
  const [island, islandLoading] = useObjectVal(database.ref(`islands/${iid}`));

  const [banlist, banlistLoading] = useObjectVal(
    island != null ? database.ref(`banlists/${island.banlist}`) : null
  );
  const [waitingList, loadingWaitingList] = useList(database.ref(`waitinglists/${iid}`));
  const [waitingStatus, loadingWaitingStatus] = useObjectVal(
    user != null ? database.ref(`waitinglists/${iid}/${user.uid}`) : null
  );
  const [guestStatus] = useObjectVal(
    user != null ? database.ref(`islands/${iid}/guests/${user.uid}`) : null
  );

  const isOnIsland = guestStatus != null && guestStatus.settled == null;
  const isOnWaitingList = waitingStatus != null && !isOnIsland;

  const isUserBanned = banlist
    ? Object.entries(banlist.bans || {}).some(([mid, isBanned]) => user?.uid === mid && isBanned)
    : false;

  const [dodoCodes] = useDodoCodes();

  const dodoCode = useMemo(() => {
    return dodoCodes?.[iid]?.value;
  }, [dodoCodes, iid]);

  const [totalGuests, setTotalGuests] = useState(0);
  useEffect(() => {
    getTotalGuests(iid).then((total) => setTotalGuests(total));
  });

  const isLineFull =
    island != null ? island.maxTotalGuests > 0 && totalGuests >= island.maxTotalGuests : false;

  const isClosed = island?.lineClosed;
  const isPasswordProtected = island?.passwordProtected;

  const [submitting, setSubmitting] = useState(false);
  const joinIslandWaitingList = useCallback(
    async ({
      username,
      island: islandName,
      message,
      password,
    }: {
      username: ?string,
      island: ?string,
      message: ?string,
      password: ?string,
    }) => {
      if (island == null) return;
      if (user == null) return;
      setSubmitting(true);

      const isp = await getIsp();

      // When user joins a waitinglist, make sure to also update its user profile with
      // the most recent user-provided data and with the user role
      database.ref(`users/${user.uid}`).update({
        username,
        island: islandName,
        role,
        isp,
      });

      const callJoinIslandWaitingList = functions.httpsCallable('joinIslandWaitingList');

      try {
        const { data: response } = await callJoinIslandWaitingList({
          isp,
          username,
          islandName,
          iid: iid,
          password,
          message,
        });

        setSubmitting(false);
        switch (response?.code) {
          case 'PASSWORD_INCORRECT':
            alert(strings.wrongPassword);
            break;
          case 'ISLAND_FULL':
            alert(strings.lineIsFull);
            break;
          case 'SUCCESS':
            break;
          case 'UNKNOWN_ERROR':
          default:
            alert(strings.somethingWentWrong);
            break;
        }
      } catch (err) {
        alert(strings.somethingWentWrong);
      }
    },
    [iid, user, role, island]
  );

  const dropIslandWaitingList = useCallback(() => {
    if (user == null) return;
    database.ref(`waitinglists/${iid}/${user.uid}`).remove((error) => {
      if (!error) {
        firestore.collection('userlogs').add({
          uid: user.uid,
          iid: iid,
          island: island?.island,
          action: 'leftQueue',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
    });
  }, [iid, user, island?.island]);

  const submit = useCallback(
    async (evt) => {
      evt.preventDefault();
      const data: {
        username: ?string,
        island: ?string,
        message: ?string,
        password: ?string,
      } = (Object.fromEntries(new FormData(evt.target).entries()): any);
      if ('Notification' in window) {
        await Notification.requestPermission();
      }
      joinIslandWaitingList({ message: '', ...data });

      window.gtag?.('event', 'join-waiting-list', {
        event_category: 'engagement',
        event_label: 'Join Island Waiting List',
      });
    },
    [joinIslandWaitingList]
  );

  const leaveIsland = useCallback(() => {
    if (user == null) return;

    database.ref(`/waitinglists/${iid}/${user.uid}`).remove();
    database
      .ref(`/islands/${iid}/guests/${user.uid}`)
      .update({ settled: firebase.database.ServerValue.TIMESTAMP });
    database.ref(`/notifications/${iid}/${user.uid}`).remove();
    history.push('/');
  }, [iid, user, history]);

  const urlParams = new URLSearchParams(window.location.search);

  const passwordFromUrl = urlParams.get('password');

  /**
   * Update the role status in the waitinglist when/if it changes
   */
  useEffect(() => {
    if (user == null) return;

    const userRoleRef = database.ref(`users/${user.uid}/role`);
    const onUserRoleChange = async (snap) => {
      if (user == null) return;
      if (!isOnWaitingList) return;

      const role = snap.val();
      if (role === 'premium') {
        database.ref(`waitinglists/${iid}/${user.uid}/role`).set(role);
      }
    };
    userRoleRef.on('value', onUserRoleChange);

    return () => {
      userRoleRef.off('value', onUserRoleChange);
    };
  });
  const loading =
    loadingWaitingStatus ||
    islandLoading ||
    loadingWaitingList ||
    loadingCurrentUserData ||
    banlistLoading ||
    loggingIn ||
    iid == null;

  return {
    passwordFromUrl,
    leaveIsland,
    submit,
    dropIslandWaitingList,
    isClosed,
    isPasswordProtected,
    isLineFull,
    dodoCode,
    isUserBanned,
    isOnWaitingList,
    loadingWaitingStatus,
    waitingList,
    loadingWaitingList,
    banlistLoading,
    islandLoading,
    currentUserData,
    loadingCurrentUserData,
    loading,
    island,
    isOnIsland,
    iid,
    submitting,
  };
};

const Card = styled.div`
  position: relative;
  border-radius: 10px;
  height: 70px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.8em;
  > span {
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &::before {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    top: 50%;
    height: 2px;
    background-color: ${(props) => transparentize(0.8, darken(0.2, invert(props.background)))};
    box-shadow: inset 0px 1px 0px 0px #00000030;
  }
`;

const Slot = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex};
  margin: 0.5em;
`;

const Label = styled(({ children, className }) => (
  <div className={className}>
    <span>{children}</span>
  </div>
))`
  color: var(--color);
  font-size: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const WaitingForDodoHero = ({
  waitingListLength,
  islandName,
  guestsLength,
  dodoCode,
  className,
}: {
  waitingListLength: number,
  islandName: string,
  guestsLength: number,
  dodoCode: ?string,
  className?: string,
}): React$Node => {
  return (
    <div
      css={`
        display: flex;
        margin: 2em -0.5em;
        isolation: isolate;
        @media (max-width: 800px) {
          flex-direction: column;
          margin: 1em -0.5em 2em -0.5em;
        }
      `}
      className={className}
    >
      {dodoCode == null && (
        <Slot flex={1}>
          <Label>{capitalize(strings.beforeYou)}</Label>
          <Card flex={1} background={colors.blueDodo} color={colors.white}>
            <span>
              {typeof waitingListLength === 'number'
                ? Math.max(waitingListLength, 0)
                : waitingListLength}
            </span>
          </Card>
        </Slot>
      )}

      <Slot flex={2}>
        <Label>{strings.island}</Label>
        <Card flex={2} background={colors.darkGray} color={colors.white}>
          <span css="font-size: 0.5em;">
            <span>{islandName}</span>
          </span>
          <span css="font-size: 0.3em;">
            <span>{strings.formatString(strings.usersOnIsland, guestsLength)}</span>
          </span>
        </Card>
      </Slot>

      <Slot flex={2}>
        <Label>{strings.dodoCode}</Label>
        <Card flex={2} background={colors.darkGray} color={colors.white}>
          <span>{dodoCode ?? '?????'}</span>
        </Card>
      </Slot>
    </div>
  );
};

const JoinIslandWaitingList = ({
  slug,
  user,
  loggingIn,
  hideNavigation = false,
  fullScreen = false,
}: {
  slug: string,
  user: ?User,
  loggingIn: boolean,
  hideNavigation?: boolean,
  fullScreen?: boolean,
}): React$Node => {
  const history = useHistory();
  const {
    passwordFromUrl,
    leaveIsland,
    submit,
    dropIslandWaitingList,
    isClosed,
    isPasswordProtected,
    isLineFull,
    dodoCode,
    isUserBanned,
    isOnWaitingList,
    waitingList,
    islandLoading,
    currentUserData,
    loading,
    island,
    isOnIsland,
    submitting,
  } = useWaitingList({ user, slug, loggingIn });

  return (
    <Container
      fullScreen={fullScreen}
      loading={loading}
      loadingText={`${strings.loading}...`}
      css={arc('joinQueueBlue', hideNavigation)}
    >
      {/* <GlobalStyle icon={island?.icon} /> */}
      <NewDodoAvailable hidden user={user} />

      {!hideNavigation && (
        <Link css={fatLink} to="/">
          {strings.backToHomePage}
        </Link>
      )}

      <h2
        css={`
          align-self: stretch;
          text-align: center;
          margin-bottom: 60px;
          margin-top: 25px;
          color: ${colors.black};
        `}
      >
        {loading
          ? strings.loading
          : island?.island == null
          ? strings.islandNotFoundTitle
          : isOnIsland
          ? strings.youAreOnIsland
          : isOnWaitingList
          ? strings.youAreOnWaitingList
          : strings.joinWaitingList}
      </h2>

      {isUserBanned ? (
        <p css="margin: 2em 0">{strings.userIsBanned}</p>
      ) : island?.island != null ? (
        <form
          onSubmit={submit}
          css={`
            width: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          {isOnIsland && (
            <div css="margin: 3em 0">
              <p>{strings.leaveIslandInstructions}</p>
              <button css="margin-top: 1em; margin-bottom: 1em;" onClick={leaveIsland}>
                {strings.iLeftTheIsland}
              </button>
            </div>
          )}
          {(isOnWaitingList || isOnIsland) && (
            <>
              <WaitingForDodoHero
                waitingListLength={
                  sortWaitingList(waitingList?.map((x) => ({ ...x.val(), key: x.key }))).findIndex(
                    (x) => x.key === user?.uid
                  ) ?? '?'
                }
                islandName={island.island}
                guestsLength={
                  Object.values(island.guests ?? {}).filter((guest: any) => guest.settled == null)
                    .length ?? '?'
                }
                dodoCode={dodoCode}
              />
              <BecomePremiumBanner
                user={user}
                text={strings.becomePremiumWhileInQueue}
                loggingIn={loggingIn}
              />
            </>
          )}
          {!isOnWaitingList && !isOnIsland && (
            <p>
              {strings.formatString(
                strings.usersInLine,
                waitingList?.length,
                Object.values(island.guests ?? {}).filter((x: any) => x.settled == null).length ??
                  '?',
                Object.values(island.guests ?? {}).filter((x: any) => x.settled != null).length ??
                  '?'
              )}
            </p>
          )}
          <p>
            {(() => {
              switch (island.icon) {
                case 'turnip':
                  return strings.formatString(
                    island.exchangeType === 'sell'
                      ? strings.waitingListDescriptionSellTurnip
                      : strings.waitingListDescriptionBuyTurnip,
                    <strong>{island.island}</strong>,
                    <strong>{island.price}</strong>
                  );
                case 'scheme':
                  return strings.formatString(
                    island.price === 0
                      ? strings.waitingListDescriptionSchemeFree
                      : strings.waitingListDescriptionScheme,
                    <strong>{island.island}</strong>,
                    <strong>{island.goodName}</strong>,
                    <strong>{island.price}</strong>
                  );
                default:
                  return island.price === 0
                    ? strings.formatString(
                        strings.waitingListDescriptionGenericFree,
                        <strong>{island.island}</strong>,
                        <strong>{`${strings[island.icon]}${
                          island.goodName ? ` (${island.goodName})` : ''
                        }`}</strong>
                      )
                    : strings.formatString(
                        strings.waitingListDescriptionGeneric,
                        <strong>{island.island}</strong>,
                        <strong>{`${strings[island.icon]}${
                          island.goodName ? ` (${island.goodName})` : ''
                        }`}</strong>,
                        <strong>{island.price}</strong>
                      );
              }
            })()}
          </p>

          {(isLineFull || isClosed) && !isOnIsland && !isOnWaitingList && user != null && (
            <div
              css={`
                text-align: center;
                background: ${colors.red};
                border-radius: 20px;
                padding: 20px;
                max-width: 300px;
                margin: 2em auto;
              `}
            >
              <AlertOctagon
                size={96}
                css={`
                  color: ${colors.darkRed};
                `}
              />
              <p css="margin-top: 2em">{isClosed ? strings.lineIsClosed : strings.lineIsFull}</p>
            </div>
          )}
          {island?.rules && (
            <>
              <h3 css="margin-top: 2em">{strings.islandRules}</h3>
              <p css="color: gray; font-size: .8em">({strings.islandRulesDisclaimer})</p>
              <p>{island?.rules.split('\n').flatMap((e, i) => [e, <br key={i} />])}</p>
            </>
          )}

          {island?.streamUrl && (
            <p css="margin-top: 1em">
              <StreamButton href={island.streamUrl} />
            </p>
          )}

          {user == null ? (
            <div
              css={`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 30px 0;
                padding: 10px 0;
              `}
            >
              <Login loggingIn={loggingIn} />
            </div>
          ) : isOnWaitingList ? (
            <div css="align-self: flex-end;">
              <button
                type="button"
                css={`
                  cursor: pointer;
                  background-color: ${colors.blueDodo};
                  border-radius: 20px;
                  padding: 15px 20px;
                  color: #000;
                  margin-top: 20px;
                  text-align: center;
                `}
                onClick={() => {
                  dropIslandWaitingList();
                  history.push('/');
                }}
              >
                {strings.quitWaitingList}
              </button>
            </div>
          ) : isLineFull || isClosed ? (
            <div />
          ) : !isOnIsland ? (
            <>
              <h3 css="margin-top: 2em">{strings.joinWaitingList}</h3>
              {isPasswordProtected && <p>{strings.passwordProtectedExplanation}</p>}
              <div
                css={`
                  display: grid;
                  grid-gap: 10px;
                  grid-template-columns: 1fr 1fr;
                  grid-template-areas:
                    'password password'
                    'username island'
                    'message message';
                  margin-top: 20px;

                  @media (max-width: 800px) {
                    grid-template-columns: 1fr;
                    grid-template-areas:
                      'password'
                      'username'
                      'island'
                      'message';
                  }
                `}
              >
                {isPasswordProtected && (
                  <InputBox label={strings.password} css="grid-area: password">
                    <input
                      type="text"
                      name="password"
                      defaultValue={passwordFromUrl}
                      required
                      disabled={user == null}
                    />
                  </InputBox>
                )}
                <InputBox label={strings.playerName} css="grid-area: username">
                  <input
                    type="text"
                    defaultValue={currentUserData?.username}
                    name="username"
                    required
                    disabled={user == null}
                  />
                </InputBox>
                <InputBox label={strings.islandName} css="grid-area: island">
                  <input
                    type="text"
                    name="island"
                    maxLength={10}
                    defaultValue={currentUserData?.island}
                    required
                    disabled={user == null}
                  />
                </InputBox>
                <InputBox label={strings.messageForTheHost} css="grid-area: message">
                  <textarea name="message" disabled={user == null} maxLength={1000} />
                </InputBox>
              </div>
              <button
                type="submit"
                disabled={user == null || submitting}
                css={`
                  cursor: pointer;
                  background-color: ${colors.blueDodo};
                  border-radius: 20px;
                  padding: 15px 20px;
                  color: #000;
                  margin-top: 20px;
                  text-align: center;
                  align-self: flex-end;
                `}
              >
                {strings.joinWaitingList}
              </button>
            </>
          ) : null}
        </form>
      ) : !islandLoading ? (
        <p css="margin-top: 2em">{strings.islandNotFound}</p>
      ) : null}
    </Container>
  );
};

export default JoinIslandWaitingList;
