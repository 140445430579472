import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/performance';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDZLip9zcrhsSG5BZnV8PMLtkqgLGPbWuU',
  authDomain: 'mydodo.link',
  databaseURL: `${
    process.env.REACT_APP_FIREBASE_PROTOCOL ?? 'https'
  }://turnip-exchange-f5d9b.firebaseio.com`,
  projectId: 'turnip-exchange-f5d9b',
  storageBucket: 'turnip-exchange-f5d9b.appspot.com',
  messagingSenderId: '473950994141',
  appId: '1:473950994141:web:4ace30ed6fb82399f22534',
};
firebase.initializeApp(firebaseConfig);
firebase.performance();

const firestore = firebase.firestore();
if (window.location.hostname === 'localhost') {
  firestore.useEmulator('localhost', 8080);
}
if (process.env.REACT_APP_FIREBASE_PROTOCOL === 'wss') {
  firebase.database.INTERNAL.forceWebSockets();
}

const database = firebase.database();
if (window.location.hostname === 'localhost') {
  database.useEmulator('localhost', 9000);
}

const auth = firebase.auth();
if (window.location.hostname === 'localhost') {
  auth.useEmulator('http://localhost:9099');
}

const functions = firebase.functions();
if (window.location.hostname === 'localhost') {
  functions.useEmulator('localhost', 5001);
}

export { firestore, database, auth, functions };
