import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    lang: 'en',
    backToHomePage: 'Back to home page',
    introText:
      'Do you need to sell your turnips? Ae shooting stars falling? A villager is gifting an interesting DIY recipe? My Dodo Link is here to help!',
    instructions:
      'To add a listing, click on "Manage Island", to join a waiting list, click on one of the listings available in the list below.',
    areYouSure: 'Are you sure?',
    logoutConfirmation: 'Proceed to log-out?',
    loading: 'Loading',

    // Home Page
    loginDescription: 'Login to join the queues or create islands!',
    loginWithFacebook: 'Login with Facebook',
    loginWithGoogle: 'Login with Google',
    loginWithTwitch: 'Login with Twitch',
    manageIsland: 'Your island',
    newDodoCodeAvailable: 'You have a new Dodo code available!',

    // Island Manager
    managerDescription:
      'Use this page to add your island to the list of My Dodo Link, all the users will see your listing and will have an opportunity to join your waiting list.',
    managerTimeoutExplanation:
      'Note that, once you close this app, your island will go inactive after 10 minutes, and will get removed after 2 hours.',
    islandName: 'Island name',
    dodoCode: 'Dodo code',
    price: 'Price',
    removeIsland: 'Remove Island',
    updateIsland: 'Update Island',
    registerIsland: 'Register Island',
    shareYourIsland: 'Share Your Island!',
    share: 'Share',
    shareDescription: 'Share the link below to invite your friends to join your waiting list.',
    shareWarning:
      'Heads up! Remember to click on "Register Island" otherwise the link will not result active.',
    waitingList: 'Waiting list',
    invite: 'Invite',
    kick: 'Kick from queue',
    playerName: 'Player name',
    island: 'Island',
    timeOnIsland: 'Time on island',
    served: 'Visitors',
    whatDoYouOffer: 'What do you offer?',
    tellUsAboutYourIsland: 'Tell us about your island',
    schemeName: 'DIY recipe name',
    furnitureName: 'Furniture name',
    sellingOrBuying: 'Selling or buying?',
    sell: 'Sell',
    buy: 'Buy',
    invalidDodoCode: 'The dodo code you inserted is invalid',
    waitingForCode: 'Code incoming',
    guestsRules: 'Guests Rules',
    maxTotalGuests: 'Max total guests',
    unlimited: 'Unlimited',
    closeLineDescription: 'Stop more players from joining the line',
    closeLine: 'Close the line',
    openLine: 'Open the line',
    additionalRules: 'Additional rules',
    additionalRulesDesc:
      'Can your guests talk with your villagers? Should they leave a tip? Where can they find the Nook Cranny? Use this field to define your own rules.',
    streamUrl: 'Live Stream URL',
    watchTheStream: 'Watch the live stream on {0}',
    streamUrlPlaceholder: 'Twitch, YouTube, or Facebook',
    noMessageFromHost: 'No message',
    exportServedList: 'Export list',
    automaticQueueManagement: 'Automatic Queue Management (beta)',
    automaticQueueDescription: 'In order to use this feature make sure to never leave this page!',
    advancedOptions: 'Advanced Options',
    clickToExpand: 'Click to expand',
    islandProtection: 'Island Protection',
    enableAutoQueue: 'Enable auto queue',
    autoQueueParallel: 'Max number of contemporary users',
    internetServiceProvider: 'Internet Service Provider',
    selectBanList: 'Select a ban list',
    defaultBanList: 'My Dodo Link (recommended)',
    personalBanList: 'Personal (managed by you)',
    setPassword: 'Password (optional)',
    includePasswordInUrl: 'Embed password in URL:',
    queueManagement: 'Queue Management',
    quueueManagementNotReady:
      'You need to register your island before you can access the queue management tools.',
    optional: 'optional',
    apiAccess: 'API Access',
    apiDescription: 'Below you can generate credentials to use our API',
    apiUsername: 'API Username',
    apiPassword: 'API Password',
    refreshApiPassword: 'Regenerate',
    refreshApiPrompt: 'The old password will stop working, proceed?',
    apiDocumentation: 'API Documentation',
    nobodyJoinedYourQueueYet: 'Nobody is in your queue right now.',
    islandVisitorsWillAppearHere: 'Your island visitors will appear here.',

    // Islands List
    inLine: 'in line',
    beforeYou: 'before you',
    reserve: 'Go in line!',
    noIslandsAvailable: 'No islands are available at this time',
    fullLine: 'Full',
    free: 'Free',
    schemeX: '{0} DIY recipe',
    furnitureX: '{0}',
    sellX: 'Sells {0}',
    buyX: 'Buys {0}',
    isClosed: 'Closed',

    // Join Waiting List
    youAreOnIsland: "It's your turn!",
    joinWaitingList: 'Join waiting list',
    quitWaitingList: 'Quit waiting list',
    youAreOnWaitingList: 'You are in line!',
    islandNotFoundTitle: 'Island not found',
    waitingListDescriptionSellTurnip: '{0} island sells turnips at {1} bells.',
    waitingListDescriptionBuyTurnip: '{0} island buys turnips at {1} bells.',
    waitingListDescriptionScheme: '{0} island sells the {1} DIY recipe at {2} bells.',
    waitingListDescriptionSchemeFree: '{0} island gifts the {1} DIY recipe.',
    waitingListDescriptionGeneric: '{0} island sells "{1}" at {2} bells.',
    waitingListDescriptionGenericFree: '{0} island gifts "{1}".',
    lineIsFull: "Dang! Unfortunately the line is full so you can't join this waiting list.",
    lineIsClosed: "Dang! Unfortunately the line is closed so you can't join this waiting list.",
    usersOnIsland: '{0} visitors on the island',
    usersInLine:
      'Currently there are {0} users in line, and {1} on the island. {2} have already visited the island.',
    islandRules: 'Island rules',
    islandRulesDisclaimer: 'This content has been inserted by the island owner',
    messageForTheHost: 'Message for the host',
    islandNotFound: 'The island you are looking for is not available right now.',
    waitingListModeratorDescription:
      'You are using a ban list you moderate, you can either ban or remove this user ban with the button below.',
    ban: 'Ban user',
    unban: 'Remove user ban',
    provideBanReason: 'Why are you banning this user?',
    provideUnbanReason: 'Why are you removing the ban for this user?',
    userIsBanned: 'You have been banned from this island.',
    youBannedUser: 'The user is now banned!',
    iLeftTheIsland: 'I left the island',
    leaveIslandInstructions: 'At the end of your visit, please press the button below!',
    password: 'Password',
    passwordProtectedExplanation:
      'This island is protected by a password! You may try asking it to the island owner.',
    wrongPassword: 'The inserted password is incorrect',

    // Categories
    all: 'All',
    turnip: 'Turnips',
    celeste: 'Celeste',
    'gift-red': 'Gifts',
    scheme: 'DIY Recipes',
    'star-yellow': 'Shooting Stars',
    other: 'Other',
    apple: 'Apple',
    cherry: 'Cherry',
    peach: 'Peach',
    orange: 'Orange',
    pear: 'Pear',
    coconut: 'Coconut',
    furniture: 'Furniture',

    // User page
    nameFromIsland: '{0} from {1}',
    notModeratingUser: "You don't have access to moderator tools for this user.",
    realName: 'Name',
    realNameNotAvailable: 'Not available',
    subscribeToPremiumMonthly: 'Subscribe to Premium ($1.5/month)',
    subscribeToPremiumYearly: 'Subscribe to Premium ($15/year)',
    redirectingToCheckoutPage: 'Redirecting to checkout page',
    youArePremium: 'You are Premium user!',
    youArePremiumDescription:
      "As a Premium user, you are always on top of the island's queues (along other Premiums like you) and you are supporting the cost of maintainence of My Dodo Link!",
    manageSubscription: 'Manage Subscription',
    or: 'or',
    datetime: 'Date and Time',
    logAction: 'Action',
    logData: 'Info',
    details: 'Details',
    'logaction:invited': 'Invited',
    'logaction:kicked': 'Kicked',
    'logaction:banned': 'Banned',
    'logaction:unbanned': 'Un-banned',
    'logaction:joinedQueue': 'Entered queue',
    'logaction:leftQueue': 'Left queue',
    bannedBy: 'Banned by {0}',
    unbannedBy: 'Un-banned by {0}',
    welcome: 'Welcome',
    yourProfileLink: 'Your profile link (click to copy)',

    // Premium Banner
    becomePremiumBannerBenefits:
      'Always on top of the queues, support My Dodo Link! Join for only $1.5/month!',
    becomePremiumWhileInQueue:
      'The queue is too long? Become Premium to jump to the first positions! Join for only $1.5/month!',
    becomePremiumBannerAction: 'Become Premium now!',

    // Ban list management
    permissionDenied: 'Permission denied',
    banListManagement: 'Banlist management',
    goBack: 'Go back',

    // Service Worker
    appUpdateAvailable:
      'A new version of My Dodo Link is available! It will install in {0} seconds',
    updateAppNow: 'Update now',

    // Errors
    somethingWentWrong: 'Something went wrong',
    theErrorHasBeenReported:
      'The error has been reported and our team of dodos is already at work to fix it.',

    // Twitch login
    pleaseWaithWhileLoggingIn: 'Please wait while we log you in...',
    emailAlreadyInUse:
      'The email associated to your Twitch account is already registered in our servers, this means you already logged in with a different login provider in the past, please login using your existing login.',
    loginCompletedYouCanCloseWindow: 'You are now logged in! You can close this window.',
    linkYourTwitchAccount: 'Link your Twich account',

    // Twitch extension
    useBoostBanner: 'Line is too long? Use Bits to increase your position!',
    pleaseTwitchLogin: 'Please login into Twitch to use this extension.',
    twitchIslandNotSet:
      "The broadcaster didn't provide an island for their channel yet. Try to refresh the page after they set one.",
    tryTwitchExtension: 'Try our Twitch extension and earn Bits!',
  },
  it: {
    lang: 'it',
    backToHomePage: 'Torna alla home page',
    introText:
      'Hai bisogno di vendere rape? Stanno cadendo stelle cadenti? Un abitante sta regalando uno schema interessante? My Dodo Link è qui per aiutarti!',
    instructions:
      'Per aggiungere un annuncio, clicca su "Gestisci Isola", per unirti ad una lista di attesa, clicca su uno degli annunci nella lista sottostante.',
    areYouSure: 'Sei sicuro?',
    logoutConfirmation: 'Vuoi disconnetterti?',
    loading: 'Caricamento...',

    // Home Page
    loginDescription: 'Accedi per metterti in coda o creare isole!',
    loginWithFacebook: 'Login con Facebook',
    loginWithGoogle: 'Login con Google',
    loginWithTwitch: 'Login con Twitch',
    manageIsland: 'La tua isola',
    newDodoCodeAvailable: 'Hai un nuovo codice Dodo disponibile!',

    // Island Manager
    managerDescription:
      'Usa questa pagina per aggiungere la tua isola alla lista di My Dodo Link, tutti gli utenti vedranno la tua offerta e avranno modo di unirsi alla lista di attesa.',
    managerTimeoutExplanation:
      'Nota che, dopo aver chiuso questa app, la tua isola diventerá inattiva dopo 10 minuti, e verrá rimossa dopo 2 ore.',
    islandName: 'Nome isola',
    dodoCode: 'Codice Dodo',
    price: 'Prezzo',
    removeIsland: 'Rimuovi Isola',
    updateIsland: 'Aggiorna Isola',
    registerIsland: 'Registra Isola',
    shareYourIsland: 'Condividi La Tua Isola!',
    share: 'Condividi',
    shareDescription:
      'Condividi il link qui sotto per invitare i tuoi amici ad unirsi alla tua coda.',
    shareWarning:
      'Attenzione! Ricordati di cliccare su "Registra Isola" altrimenti il link non risulterá attivo.',
    waitingList: 'Lista di attesa',
    invite: 'Invita',
    kick: 'Espelli dalla coda',
    playerName: 'Nome giocatore',
    island: 'Isola',
    timeOnIsland: "Tempo sull'isola",
    served: 'Visitatori',
    whatDoYouOffer: 'Cosa offri?',
    tellUsAboutYourIsland: 'Parlaci della tua isola',
    schemeName: 'Nome dello schema',
    furnitureName: 'Nome del mobile',
    sellingOrBuying: 'Compri o vendi?',
    sell: 'Vendo',
    buy: 'Compro',
    invalidDodoCode: 'Il codice dodo inserito non è valido',
    guestsRules: 'Regole Visitatori',
    maxTotalGuests: 'Massimo visitatori totali',
    unlimited: 'Illimitati',
    closeLineDescription: 'Impedisci ad altri giocatori di unirsi alla coda',
    closeLine: 'Chiudi la coda',
    openLine: 'Apri la coda',
    additionalRules: 'Regole addizionali',
    additionalRulesDesc:
      'I visitatori possono parlare con i tuoi abitanti? Vorresti lasciassero un regalo? Dove possono trovare la Bottega di Nook? Usa questo spazio per indicare le tue regole.',
    streamUrl: 'Link Live Stream',
    watchTheStream: 'Guarda la diretta su {0}',
    streamUrlPlaceholder: 'Twitch, YouTube, o Facebook',
    noMessageFromHost: 'Nessun messaggio',
    exportServedList: 'Esporta lista',
    advancedOptions: 'Opzioni Avanzate',
    clickToExpand: 'Clicca per espandere',
    islandProtection: 'Protezione Isola',
    automaticQueueManagement: 'Gestione Automatica Della Coda (beta)',
    automaticQueueDescription: 'Per usare questa funzione, non dovrai mai lasciare la pagina!',
    enableAutoQueue: 'Attiva coda automatica',
    autoQueueParallel: 'Numero massimo utenti contemporanei',
    internetServiceProvider: 'Operatore Telefonico',
    selectBanList: 'Seleziona una lista di blocco',
    defaultBanList: 'My Dodo Link (consigliata)',
    personalBanList: 'Personale (gestita da te)',
    setPassword: 'Password (opzionale)',
    includePasswordInUrl: 'Includi la password nel link:',
    queueManagement: 'Gestione Coda',
    quueueManagementNotReady:
      'Devi registrare la tua isola prima di poter accedere agli strumenti di gestione coda.',
    optional: 'opzionale',
    apiAccess: 'API Access',
    apiDescription: 'Qua sotto puoi generare le credenziali per utilizzare la nostra API.',
    apiUsername: 'API Username',
    apiPassword: 'API Password',
    refreshApiPassword: 'Rigenera',
    refreshApiPrompt: 'La vecchia password smetterà di funzionare, procedere?',
    apiDocumentation: 'Documentazione API',
    nobodyJoinedYourQueueYet: 'Nessuno é nella tua coda al momento.',
    islandVisitorsWillAppearHere: 'I visitatori della tua isola appariranno qui.',

    // Islands List
    inLine: 'in coda',
    beforeYou: 'prima di te',
    reserve: 'Vai in coda!',
    noIslandsAvailable: 'Nessuna isola disponibile al momento',
    waitingForCode: 'Codice in arrivo',
    fullLine: 'Piena',
    free: 'Gratis',
    schemeX: 'Schema {0}',
    furnitureX: '{0}',
    sellX: 'Vende {0}',
    buyX: 'Compra {0}',
    isClosed: 'Chiusa',

    // Join Waiting List
    youAreOnIsland: 'É il tuo turno!',
    joinWaitingList: 'Entra in lista di attesa',
    quitWaitingList: 'Esci dalla lista di attesa',
    youAreOnWaitingList: 'Sei in coda!',
    islandNotFoundTitle: 'Isola non trovata',
    waitingListDescriptionSellTurnip: "L'isola {0} vende rape a {1} stelline.",
    waitingListDescriptionBuyTurnip: "L'isola {0} acquista rape a {1} stelline.",
    waitingListDescriptionScheme: "L'isola {0} vende lo schema {1} per {2} stelline.",
    waitingListDescriptionSchemeFree: "L'isola {0} regala lo schema {1}.",
    waitingListDescriptionGeneric: 'L\'isola {0} vende "{1}" per {2} stelline.',
    waitingListDescriptionGenericFree: 'L\'isola {0} regala "{1}".',
    lineIsFull:
      "Che peccato! I posti disponibili per quest'isola sono esauriti. Sarà per la prossima volta!",
    lineIsClosed:
      'Che peccato! Al momento questa isola non accetta prenotazioni.',
    usersOnIsland: "{0} visitatori sull'isola",
    usersInLine:
      "Al momento ci sono {0} utenti in coda, e {1} sull'isola. {2} hanno già visitato l'isola. ",
    islandRules: "Regole dell'isola",
    islandRulesDisclaimer: "Questo contenuto è stato scritto dal proprietario dell'isola",
    messageForTheHost: "Messaggio per l'ospitante",
    islandNotFound: "L'isola che cerchi non è disponibile al momento.",
    waitingListModeratorDescription:
      'Stai usando una lista di blocco da te moderata, puoi bannare o rimuovere il ban di questo utente usando il bottone sottostante.',
    ban: 'Banna utente',
    unban: 'Rimuovi ban utente',
    provideBanReason: 'Per quale motivo é stato bannato?',
    provideUnbanReason: 'Per quale motivo é stato rimosso il ban?',
    userIsBanned: 'Sei stato bannato da questa isola.',
    youBannedUser: "L'utente é stato bannato!",
    iLeftTheIsland: "Ho lasciato l'isola",
    leaveIslandInstructions: 'Per favore, al termine della tua visita clicca il bottone qui sotto!',
    password: 'Password',
    passwordProtectedExplanation:
      "Quest'isola è protetta da una password! Potresti provare a chiederla al proprietario dell'isola.",
    wrongPassword: 'La password inserita non è corretta',

    // Categories
    all: 'Tutto',
    turnip: 'Rape',
    celeste: 'Celeste',
    'gift-red': 'Regali',
    scheme: 'Schemi',
    'star-yellow': 'Stelle Cadenti',
    other: 'Altro',
    apple: 'Mela',
    cherry: 'Ciliegia',
    peach: 'Pesca',
    orange: 'Arancia',
    pear: 'Pera',
    coconut: 'Cocco',
    furniture: 'Mobilia',

    // User page
    nameFromIsland: '{0} da {1}',
    notModeratingUser: 'Non hai accesso agli strumenti di moderazione per questo utente.',
    realName: 'Nome',
    realNameNotAvailable: 'Non disponibile',
    subscribeToPremiumMonthly: 'Iscriviti a Premium (€1/mese)',
    subscribeToPremiumYearly: 'Iscriviti a Premium (€10/anno)',
    redirectingToCheckoutPage: 'Reindirizzamento alla pagina di checkout',
    youArePremium: 'Sei un utente Premium!',
    youArePremiumDescription:
      'Come utente Premium, sei sempre in cima alle code delle isole (insieme agli altri utenti Premium) e supporti i costi di gestione di My Dodo Link!',
    manageSubscription: 'Gestisci abbonamento',
    or: 'oppure',
    datetime: 'Data e Ora',
    logAction: 'Azione',
    logData: 'Informazioni',
    details: 'Dettagli',
    'logaction:invited': 'Invitato',
    'logaction:kicked': 'Espulso',
    'logaction:banned': 'Bannato',
    'logaction:unbanned': 'Ban rimosso',
    'logaction:joinedQueue': 'Entrato in coda',
    'logaction:leftQueue': 'Uscito dalla coda',
    bannedBy: 'Bannato da {0}',
    unbannedBy: 'Ban rimosso da {0}',
    welcome: 'Benvenuto/a',
    yourProfileLink: 'Il link del tuo profilo (clicca per copiare)',

    // Premium Banner
    becomePremiumBannerBenefits:
      'Sempre tra i primi in coda e supporti My Dodo Link! Unisciti con €1 al mese!',
    becomePremiumWhileInQueue:
      'La coda é troppo lunga? Diventa Premium e salta in cima! Unisciti con €1 al mese!',
    becomePremiumBannerAction: 'Diventa Premium ora!',

    // Ban list management
    permissionDenied: 'Permesso negato',
    banListManagement: 'Gestione banlist',
    goBack: 'Indietro',

    // Service Worker
    appUpdateAvailable:
      'Una nuova versione di My Dodo Link é disponibile! Verrá installato tra {0} secondi.',
    updateAppNow: 'Aggiorna ora',

    // Errors
    somethingWentWrong: 'Qualcosa è andato storto',
    theErrorHasBeenReported:
      'Il problema è stato segnalato ed il nostro team di dodo è giá al lavoro per risolverlo.',

    // Twitch login
    pleaseWaithWhileLoggingIn: "Per favore attendi mentre effettuiamo l'accesso",
    emailAlreadyInUse:
      "L'email associata al tuo account Twitch è giá registrata nei nostri server, questo vuol dire che hai precedentemente effettuato l'accesso con un altro login, per favore usa il tuo login esistente per accedere.",
    loginCompletedYouCanCloseWindow: 'Login effettuato! Ora puoi chiudere questa pagina.',
    linkYourTwitchAccount: 'Collega il tuo account Twitch',

    // Twitch extension
    useBoostBanner: 'La coda è troppo lunga? Usa i Bits per salire di posizione!',
    pleaseTwitchLogin: 'Accedi su Twitch per usare questa estensione.',
    twitchIslandNotSet:
      "Lo streamer non ha impostato alcuna isola per il suo canale. Aggiorna la pagina dopo che l'isola é stata impostata.",
    tryTwitchExtension: "Prova l'estensione per Twitch e guadagna Bits!",
  },
});
