// @flow
import { useState, useEffect } from 'react';

export function useCustomClaimRoleFromUser(
  user: any,
  customRole: string = 'stripeRole'
): [?string, boolean] {
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (user == null) {
      setLoading(false);
      return;
    }

    user.getIdTokenResult(true).then((x) => {
      setRole(x.claims[customRole]);
      setLoading(false);
    });
  }, [customRole, user]);

  if (customRole === 'stripeRole' && role !== 'premium') {    
    return ['basic', loading];
  }

  return [role, loading];
}
