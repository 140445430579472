// @flow
import React from 'react';
import { useObjectVal } from 'react-firebase-hooks/database';
import { database } from './firebase';
import 'firebase/database';
import 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Container, fatLink } from './styles';
import strings from './strings';
import { Table } from './ui/Table';
import type { User } from './types';

const UserRow = ({ banlist, uid }: { banlist: string, uid: string }): React$Node => {
  const [user, loading] = useObjectVal(database.ref(`/users/${uid}`));

  if (loading || user == null) return 'error';

  return (
    <tr>
      <td>
        <Link to={`/u/${uid}`}>{user.username}</Link>
      </td>
      <td>{user.island}</td>
      <td>
        {user.firstName} {user.lastName}
      </td>
      <td>
        {' '}
        <button
          type="button"
          onClick={(evt) => {
            database.ref(`banlists/${banlist}/bans/${uid}`).remove();
          }}
        >
          {strings.unban}
        </button>
      </td>
    </tr>
  );
};

const BanListManagement = ({
  currentUser,
  loggingIn,
}: {
  currentUser: ?User,
  loggingIn: boolean,
}): React$Node => {
  const [island, islandLoading] = useObjectVal(
    database.ref(`islands/${currentUser?.uid ?? 'undefined'}`)
  );
  const [banlist, banlistLoading] = useObjectVal(
    database.ref(`banlists/${island?.banlist ?? 'undefined'}`)
  );

  const isModerator = banlist
    ? Object.entries(banlist.moderators || {}).some(
        ([mid, isMod]) => currentUser?.uid === mid && isMod
      )
    : false;

  const loading = currentUser == null || islandLoading || banlistLoading || loggingIn;

  if (!isModerator) {
    return (
      <Container loading={loading} loadingText={strings.loading}>
        <Link css={fatLink} to={`/island-manager`}>
          {strings.goBack}
        </Link>
        <h1 css="margin-top: 1em">{strings.permissionDenied}</h1>
      </Container>
    );
  }

  return (
    <Container loading={loading} loadingText={strings.loading}>
      <Link css={fatLink} to={`/island-manager`}>
        {strings.goBack}
      </Link>
      <h1 css="margin-bottom: 20px; margin-top: 1em">
        {island?.banlist === 'default' ? strings.defaultBanList : strings.personalBanList}
      </h1>
      {island != null && (
        <Table className="large even-cols">
          <thead>
            <tr>
              <th>{strings.playerName}</th>
              <th>{strings.island}</th>
              <th>{strings.realName}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {Object.keys(banlist?.bans ?? {}).map((uid) => (
              <UserRow uid={uid} banlist={island?.banlist} />
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default BanListManagement;
