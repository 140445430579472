import React from 'react';
import { usePremiumCheckoutRedirect } from './premium';
import { Container } from './styles';
import strings from './strings';

const SubscribePremium = ({ loggingIn, plan }) => {
  usePremiumCheckoutRedirect({ plan });

  return <Container loading>{strings.redirectingToCheckoutPage}</Container>;
};

export default SubscribePremium;
