// @flow
import LocalizedStrings from 'react-localization';

export const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51IECOgFJwZwniLjOkflYXMXtbf34kqmhp3FXLh48czxZuMRQUVc33hkBuuJWqOadfT7M6MxI39ysIqdx1vIsTb3a008r2q1upD';

export const localized: LocalizedStrings = new LocalizedStrings({
  en: {
    stripePremiumMonthlyPrice: 'price_1IEFV1FJwZwniLjOdbrCSPUI',
    stripePremiumAnnualPrice: 'price_1IEbIuFJwZwniLjOGM64hdPS',
  },
  it: {
    stripePremiumMonthlyPrice: 'price_1IEDKTFJwZwniLjO6bYZpgtN',
    stripePremiumAnnualPrice: 'price_1IEJbpFJwZwniLjOG5wieQQw',
  },
});
